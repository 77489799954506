import { useCallback, useEffect, useState, useMemo } from 'react';
import { useMedia } from 'react-use';
import { useRouter } from 'next/router';
import styled from '@emotion/styled';
import {
  breakpoints,
  fontStyle,
  palette,
  Progress,
  spacing,
  Typography,
  Button,
  Modal,
} from '@playdapp/ui';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import {
  GlowWalletName,
  PhantomWalletName,
} from '@solana/wallet-adapter-wallets';
import { sign } from 'tweetnacl';
import { bs58 } from '@project-serum/anchor/dist/cjs/utils/bytes';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@chakra-ui/react';
import { css } from '@emotion/react';
import { useWeb3Modal } from '@web3modal/react';
import { useDisconnect } from 'wagmi';

import type { AbstractConnector } from '@web3-react/abstract-connector';
import type { NetworkName, SolanaWalletName } from 'types/network';
import type { WalletProvider } from 'types/wallet';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getBalances, setWalletInfo } from 'store/wallet';
import { fetchUserInfo, fetchUserProfile, readyToUpdate } from 'store/user';
import { setConnector } from 'store/marketplace';
import { setConnector as setSolanaConnector } from 'store/marketplaceSolana';
import { setIsModalOpen } from 'store/connectModal';
import useWeb3Info from 'hooks/useWeb3Info';
import { connectorsByNetwork } from 'lib/connectors';
import { onLogin, removeLoginInfo } from 'lib/auth';
import { activeChainId } from 'lib/network';
import {
  getSignMessage,
  checkMobileDevice,
  checkIos,
  getBreakpointQuery,
} from 'lib/util';
import useSolana from 'hooks/useSolana';
import useToastMessage from 'hooks/useToastMessage';
import { signMessage } from 'data/word';
import { AUTH_VERSION } from 'data/auth';
import { shakeAnimation } from 'styles/mixin';

import NetworkAlert from '@/components/Modal/NetworkAlert';
import CustomButton from '@/components/Button';
import Icon from '@/components/Icon';
import HowToSignInModal from '@/components/Modal/HowToSignInModal';
import WalletDrawer from '@/components/Drawer/WalletDrawer';

type ModalProps = {
  isOpen: boolean;
  handleOpen: (isOpen: boolean) => void;
  onMenuClose?: () => void;
};

type AdditionalBlockProps = {
  type: 'Popular' | 'Solana';
};

type Wallet = {
  name: WalletProvider;
  typo: string;
};

type Network = {
  networkName: NetworkName;
  networkTypo: string;
};

type WalletButtonGroupProps = {
  errorNetworkMessage: string;
  solanaWalletDownloadCheck: {
    name: string;
    title: string;
    isDownload: boolean;
    url: string;
  }[];
  isEthereumProvider: boolean;
  handleInstallMetaMask: () => void;
  isMobile: boolean;
  selectedWalletProvider: WalletProvider | null;
  handleConnectWallet: (
    type: WalletProvider,
    network: NetworkName,
  ) => () => void;
  handleSolanaConnectWallet: (
    type: WalletProvider,
    network: 'solana',
  ) => () => void;
  handleShowOpen: () => void;
  isClicked: boolean;
};

const ModalContents = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: -0.25rem -1rem;
  max-height: 100vh;
  overflow-y: scroll;
  z-index: 3;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const SignInTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing.m};

  ${breakpoints.down('md')} {
    margin-bottom: ${spacing.s};
  }
`;

const IconBox = styled.button<{ disabled: boolean }>`
  width: 32px;
  height: 32px;

  &:hover {
    opacity: 0.6;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;

      &:hover {
        opacity: 0.5;
      }
    `}
`;

const ConnectWalletAction = styled(Accordion)`
  display: flex;
  flex-direction: column;
  gap: 8px;

  &.shake {
    animation: ${shakeAnimation} 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
`;

const ConnectWalletBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${({ theme }) => theme.rem(28)};
`;

const ConnectButton = styled(AccordionButton)<{
  opacity?: string;
  cursor?: string;
  hover?: string;
}>`
  justify-content: space-between;
  width: 312px;
  height: 48px;
  border-radius: 24px;
  border: 1px solid ${palette.gray600};
  padding: ${spacing.s} ${spacing.m};
  opacity: ${({ opacity }) => opacity};
  cursor: ${({ cursor }) => cursor};

  &:focus {
    box-shadow: none !important;
  }

  span {
    ${fontStyle('b5')};
    font-size: 12px;
  }

  p {
    margin-left: ${spacing.l};
  }

  span:last-of-type {
    margin-left: ${spacing.xs};
  }

  &:hover {
    background-color: ${({ hover }) => hover};
  }

  ${breakpoints.down('md')} {
    width: 100%;
    background-color: ${palette.white};

    &:hover {
      background-color: ${palette.white};
    }
  }
`;

const ConnectButton2 = styled(CustomButton)`
  justify-content: space-between;
  width: 312px;
  height: 48px;
  border-radius: 24px;
  border: 1px solid ${palette.gray600};
  padding: ${spacing.s} ${spacing.m};
  margin-bottom: ${spacing.xs};

  span {
    ${fontStyle('b5')};
    font-size: 12px;
  }

  p {
    margin-left: ${spacing.l};
  }

  span:last-of-type {
    margin-left: ${spacing.xs};
  }

  &:hover {
    background-color: rgba(208, 209, 215, 0.15);
  }

  ${breakpoints.down('md')} {
    width: 100%;
  }
`;

const ConnectDescription = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AdditionalBlock = styled.div<AdditionalBlockProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.125rem 0.5rem;
  border-radius: 4px;
  background-color: ${({ type }) =>
    type === 'Popular' ? `${palette.primary100}` : `${palette.blue100}`};

  ${fontStyle('p6')};
  color: ${({ type }) =>
    type === 'Popular' ? `${palette.primary700}` : `${palette.dgray600}`};
`;

const ShowMoreBlock = styled(AccordionButton)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: ${spacing.s};

  hr {
    flex-grow: 1;
    border-top: 1px solid ${palette.gray600};
  }

  &:focus {
    box-shadow: none !important;
  }
`;

const ShowMoreButton = styled.button`
  display: flex;
  gap: 0.375rem;
  margin-left: ${spacing.s};
  padding: 0.375rem ${spacing.s};
  ${fontStyle('p4')};
  color: ${palette.dgray600};
  outline: none;
`;

const HelpBlock = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${spacing.xl};

  span {
    color: #0aa4e7 !important;
    text-decoration: underline;
  }

  button {
    padding: 0;
  }

  button:hover {
    opacity: 0.8;
  }

  button + button {
    margin-left: ${spacing.xl};
  }
`;

const ErrorMessage = styled.p`
  margin: ${spacing.s} 0 0 0;
  color: #ef4d4d;
`;

const CustomAccordionItem = styled(AccordionItem)`
  width: 100%;
  border: none;
`;

const CustomAccordionPanel = styled(AccordionPanel)`
  padding: ${spacing.m};
  border: 1px solid ${palette.gray600};
  border-top: none;
  border-radius: 0 0 24px 24px;

  hr {
    width: 280px;
    margin-top: -16px;
    margin-bottom: 12px;

    ${breakpoints.down('md')} {
      width: 100%;
    }
  }

  p {
    margin-bottom: ${spacing.xxs};
    ${fontStyle('p6')}
    color: ${palette.gray900};
  }

  ${breakpoints.down('md')} {
    width: 100%;
  }
`;

const MoreAccordionPanel = styled(AccordionPanel)`
  width: 100%;
  padding: 0;
`;

const ConnectNetworkButton = styled.button<{ disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${spacing.xs};
  gap: ${spacing.xs};
  width: 280px;
  height: 32px;
  ${fontStyle('p5')}
  background-color: ${palette.gray200};
  border-radius: 16px;

  &:hover {
    background-color: ${palette.gray300};
  }

  &:active {
    background-color: ${palette.gray400};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;

      &:hover {
        background-color: ${palette.gray200};
      }

      &:active {
        background-color: ${palette.gray200};
      }
    `}

  ${breakpoints.down('md')} {
    width: 100%;
  }
`;

const WALLET_LIST: Wallet[] = [
  {
    name: 'metamask',
    typo: 'MetaMask',
  },
  {
    name: 'portis',
    typo: 'Portis',
  },
  {
    name: 'walletlink',
    typo: 'Coinbase Wallet',
  },
  {
    name: 'walletconnect',
    typo: 'WalletConnect',
  },
  {
    name: 'torus',
    typo: 'Torus',
  },
];

const MORE_WALLET_LIST: Wallet[] = [
  {
    name: 'phantom',
    typo: 'Phantom',
  },
  {
    name: 'glow',
    typo: 'Glow',
  },
];

const NETWORK_LIST: Network[] = [
  {
    networkName: 'polygon',
    networkTypo: 'Polygon',
  },
  {
    networkName: 'ethereum',
    networkTypo: 'Ethereum',
  },
];

const MOBILE_DEEP_LINK = {
  phantom: `https://phantom.app/ul/browse/https%3A%2F%2F${
    process.env.NEXT_PUBLIC_ENV === 'development'
      ? 'market.qa.playdapp.com'
      : 'playdapp.com'
  }`,
  glow: 'https://glow.app/download?utm_source=playdapp',
};

const DescriptionBlock = ({ type }: AdditionalBlockProps) => (
  <AdditionalBlock type={type}>
    {type === 'Solana' && <Icon name="solana" size={12} />}
    <div>{type}</div>
  </AdditionalBlock>
);

const WalletButtonGroup = ({
  errorNetworkMessage,
  solanaWalletDownloadCheck,
  isEthereumProvider,
  handleInstallMetaMask,
  isMobile,
  selectedWalletProvider,
  handleConnectWallet,
  handleSolanaConnectWallet,
  handleShowOpen,
  isClicked,
}: WalletButtonGroupProps) => (
  <ConnectWalletBox>
    {errorNetworkMessage && <ErrorMessage>{errorNetworkMessage}</ErrorMessage>}

    <ConnectWalletAction defaultIndex={[-1]} allowToggle>
      {WALLET_LIST.map((item) => {
        const { name, typo } = item;
        const walletDownloadInfo = solanaWalletDownloadCheck.find(
          (item) => item.name === name,
        );

        if (name === 'metamask' && !isEthereumProvider) {
          return (
            <CustomAccordionItem>
              <ConnectButton
                key={`install-${name}`}
                isOutlined
                onClick={handleInstallMetaMask}
                isDisabled={!!selectedWalletProvider}
              >
                <ConnectDescription>
                  <Icon name={name} size={24} />
                  <Typography type="b3">
                    {!isMobile && 'Install '}MetaMask
                  </Typography>
                </ConnectDescription>
                <DescriptionBlock type="Popular" />
              </ConnectButton>
            </CustomAccordionItem>
          );
        }

        if (walletDownloadInfo && !walletDownloadInfo.isDownload) {
          return (
            <CustomAccordionItem>
              <ConnectButton
                key={`install-${name}`}
                isOutlined
                to={walletDownloadInfo.url}
                isDisabled={!!selectedWalletProvider}
              >
                <ConnectDescription>
                  <Icon name={name} size={24} />
                  <Typography type="b5">
                    {!isMobile && 'Install '}
                    {walletDownloadInfo.title}
                  </Typography>
                </ConnectDescription>
                {name === 'metamask' && <DescriptionBlock type="Popular" />}
                {name === 'phantom' && <DescriptionBlock type="Solana" />}
              </ConnectButton>
            </CustomAccordionItem>
          );
        }

        return (
          <CustomAccordionItem>
            <ConnectButton
              key={`wallet-button-${name}`}
              isOutlined
              opacity={!selectedWalletProvider ? '1' : '0.7'}
              cursor={!selectedWalletProvider ? 'pointer' : 'not-allowed'}
              hover={
                !selectedWalletProvider ? '(208, 209, 215, 0.15)' : 'white'
              }
              _expanded={{
                border: '1px solid #D0D1D7',
                borderBottom: '0',
                borderRadius: '24px 24px 0 0',
              }}
            >
              {selectedWalletProvider === name ? (
                <ConnectDescription>
                  <Progress size="small" color={palette.primary700} />
                  <Typography type="b5" component="p" color="marketplace">
                    Connecting...
                  </Typography>
                </ConnectDescription>
              ) : (
                <>
                  <ConnectDescription>
                    <Icon name={name} size={24} />
                    <Typography type="b5">{typo}</Typography>
                  </ConnectDescription>
                  {name === 'metamask' && <DescriptionBlock type="Popular" />}

                  {name === 'phantom' && <DescriptionBlock type="Solana" />}
                </>
              )}
            </ConnectButton>
            <CustomAccordionPanel>
              <hr />
              <p>Select network you want to connect</p>
              {NETWORK_LIST.map((item) => {
                const { networkName, networkTypo } = item;
                return (
                  <ConnectNetworkButton
                    onClick={handleConnectWallet(name, networkName)}
                    disabled={selectedWalletProvider === name}
                  >
                    <Icon
                      name={`brand-${networkName}`}
                      size={networkName === 'polygon' ? '20' : '24'}
                    />
                    {networkTypo}
                  </ConnectNetworkButton>
                );
              })}
            </CustomAccordionPanel>
          </CustomAccordionItem>
        );
      })}

      <CustomAccordionItem>
        <ShowMoreBlock>
          <hr />
          <ShowMoreButton
            onClick={() => {
              handleShowOpen();
            }}
          >
            {isClicked ? 'Show More' : 'Show Less'}
            <Icon
              name={isClicked ? 'arrow-down' : 'arrow-up'}
              width={10}
              height={20}
            />
          </ShowMoreButton>
          <hr />
        </ShowMoreBlock>
        <MoreAccordionPanel>
          {MORE_WALLET_LIST.map((item) => {
            const { name, typo } = item;
            const walletDownloadInfo = solanaWalletDownloadCheck.find(
              (item) => item.name === name,
            );

            if (walletDownloadInfo && !walletDownloadInfo.isDownload) {
              return (
                <ConnectButton2
                  key={`install-${name}`}
                  isOutlined
                  to={walletDownloadInfo.url}
                  isDisabled={!!selectedWalletProvider}
                >
                  <ConnectDescription>
                    <Icon name={name} size={24} />
                    <Typography type="b5">
                      {!isMobile && 'Install '}
                      {walletDownloadInfo.title}
                    </Typography>
                  </ConnectDescription>
                  {name === 'glow' && <DescriptionBlock type="Solana" />}
                </ConnectButton2>
              );
            }

            return (
              <ConnectButton2
                key={`wallet-button-${name}`}
                isOutlined
                isDisabled={!!selectedWalletProvider}
                onClick={handleSolanaConnectWallet(name, 'solana')}
              >
                {selectedWalletProvider === name ? (
                  <ConnectDescription>
                    <Progress size="small" color={palette.primary700} />
                    <Typography type="b5" component="p" color="marketplace">
                      Connecting...
                    </Typography>
                  </ConnectDescription>
                ) : (
                  <>
                    <ConnectDescription>
                      <Icon name={name} size={24} />
                      <Typography type="b5">{typo}</Typography>
                    </ConnectDescription>
                    {name === 'glow' ||
                      ('phantom' && <DescriptionBlock type="Solana" />)}
                  </>
                )}
              </ConnectButton2>
            );
          })}
        </MoreAccordionPanel>
      </CustomAccordionItem>
    </ConnectWalletAction>
  </ConnectWalletBox>
);

const SignInModal = ({ isOpen, handleOpen, onMenuClose }: ModalProps) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { activate, deactivate } = useWeb3React<Web3Provider>();
  const { account, chainId, library, active } = useWeb3Info();
  const { disconnect: wagmiDisconnect } = useDisconnect();

  const { open } = useWeb3Modal();

  const {
    connected,
    chainId: solanaChainId,
    publicKey,
    wallet: solanaWallet,
    wallets: solanaWallets,
    select,
    disconnect,
    signMessage: solanaSignMessage,
  } = useSolana();

  const { handleUpdateToast, handleCloseToast } = useToastMessage({
    position: 'top',
    duration: 5000,
  });

  const isMdDown = useMedia(getBreakpointQuery(breakpoints.down('md')));

  const [selectedNetwork, setSelectedNetwork] =
    useState<NetworkName>('polygon');
  const [selectedWalletProvider, setSelectedWalletProvider] =
    useState<WalletProvider | null>(null);
  const [errorNetworkMessage, setErrorNetworkMessage] = useState('');
  const [isNetworkErrorModalOpen, setIsNetworkErrorModalOpen] = useState(false);
  const [isEthereumProvider, setIsEthereumProvider] = useState(true);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isMobile, setIsMobile] = useState(true);
  const [isIos, setIsIos] = useState(true);
  const [isClicked, setIsClicked] = useState(true);
  const [isConnectHelpOpen, setIsConnectHelpOpen] = useState(false);

  const { isModalOpen } = useAppSelector(({ connectModal }) => connectModal);

  const solanaWalletDownloadCheck = useMemo(
    () =>
      solanaWallets.map((item) => {
        const { adapter, readyState } = item;
        const { name, url } = adapter;

        return {
          readyState,
          name: name.toLowerCase(),
          title: name,
          isDownload: readyState === 'Installed',
          url:
            isMobile || name.toLowerCase() === 'glow' || isIos
              ? MOBILE_DEEP_LINK[name.toLowerCase() as SolanaWalletName]
              : url,
        };
      }),
    [solanaWallets, isMobile],
  );

  const handleConnectHelpOpen = useCallback(() => {
    setIsConnectHelpOpen(!isConnectHelpOpen);
  }, [isConnectHelpOpen]);

  const handleNetworkErrorModalOpen = useCallback(
    (openState: boolean, options?: { keepSelectedWallet?: boolean }) => {
      setIsNetworkErrorModalOpen(openState);
      if (!openState && !options?.keepSelectedWallet) {
        setSelectedWalletProvider(null);
      }
      if (!openState && isConnecting) setIsConnecting(false);
    },
    [],
  );

  const handleShowOpen = () => {
    setIsClicked(!isClicked);
  };

  const handleConnectWallet =
    (type: WalletProvider, network: NetworkName) => async () => {
      removeLoginInfo();
      setIsConnecting(true);

      setErrorNetworkMessage('');
      if (selectedNetwork !== network) {
        setSelectedNetwork(network);
      }

      setSelectedWalletProvider(type);

      if (type === 'walletconnect') {
        open();
        return;
      }

      const connector = connectorsByNetwork[selectedNetwork][type];

      if (!connector) {
        return;
      }

      await activate(connector as AbstractConnector, (error) => {
        // TODO: Fail Connect Wallet
        if (error instanceof UnsupportedChainIdError) {
          handleNetworkErrorModalOpen(true);
          return;
        }

        setSelectedWalletProvider(null);
      });
    };

  const handleSolanaConnectWallet =
    (type: WalletProvider, network: 'solana') => async () => {
      removeLoginInfo();

      setIsConnecting(true);
      setErrorNetworkMessage('');
      setSelectedWalletProvider(type);
      setSelectedNetwork(network);

      try {
        const wallet = type === 'phantom' ? PhantomWalletName : GlowWalletName;
        select(wallet);
        localStorage.setItem('PDMP_WALLET_PROVIDER', type);
        localStorage.setItem('PDMP_NETWORK', 'solana');
      } catch (error) {
        handleNetworkErrorModalOpen(true);
        setSelectedWalletProvider(null);
        setIsConnecting(false);
      }
    };

  const handleInstallMetaMask = () => {
    if (isMobile) {
      window.open(
        `https://metamask.app.link/dapp/${
          process.env.NEXT_PUBLIC_ENV === 'development'
            ? 'market.qa.playdapp.com'
            : 'playdapp.com'
        }`,
        '_blank',
      );
    } else {
      window.open('https://metamask.io/download/', '_blank');
    }
  };

  const handleConnectOff = useCallback(() => {
    dispatch(setIsModalOpen(false));

    const body = document.querySelector('body');

    if (!body) return;

    body.style.overflowY = 'auto';
  }, [dispatch]);

  const resetConnect = useCallback(() => {
    setSelectedWalletProvider(null);
    handleNetworkErrorModalOpen(false);
    setIsConnecting(false);

    if (account) deactivate();

    if (publicKey) disconnect();

    if (wagmiDisconnect) wagmiDisconnect();
  }, [
    account,
    publicKey,
    handleNetworkErrorModalOpen,
    deactivate,
    wagmiDisconnect,
  ]);

  useEffect(() => {
    if (
      isConnecting &&
      selectedNetwork &&
      selectedWalletProvider &&
      ((account && library && chainId) || (connected && publicKey))
    ) {
      const isEthereumNetwork = account && library && chainId;

      let walletAccount: string | null | undefined = '';

      if (publicKey) {
        walletAccount = publicKey.toString();
      } else {
        walletAccount = account;
      }

      if ((!account && !publicKey) || !walletAccount)
        throw new Error('Account not found!');

      (async () => {
        try {
          let responseSign = null;

          if (isEthereumNetwork) {
            responseSign = await library.send('personal_sign', [
              getSignMessage(signMessage(walletAccount)),
              walletAccount,
            ]);
          } else {
            if (!solanaSignMessage)
              throw new Error('Wallet does not support message signing!');

            const message = new TextEncoder().encode(
              signMessage(walletAccount),
            );

            let signature: Uint8Array;

            try {
              signature = await solanaSignMessage(message);
            } catch (e) {
              throw new Error(`sign message fail`);
            }

            if (
              publicKey &&
              !sign.detached.verify(message, signature, publicKey.toBytes())
            )
              throw new Error('Invalid signature!');

            responseSign = bs58.encode(signature);
          }

          await onLogin({
            account: walletAccount,
            provider: selectedWalletProvider,
            network: selectedNetwork,
            sign: responseSign,
            version: AUTH_VERSION,
          });

          if (publicKey) {
            dispatch(setSolanaConnector());
          } else {
            dispatch(
              setWalletInfo({
                account: walletAccount,
                provider: selectedWalletProvider,
                activeChainId: activeChainId[selectedNetwork],
                library,
                chainId:
                  chainId || activeChainId[selectedNetwork] || solanaChainId,
                solanaWalletAdapter: solanaWallet ? solanaWallet.adapter : null,
              }),
            );

            if (selectedWalletProvider === 'kaikas') {
              const activeChain = activeChainId[selectedNetwork];

              if (activeChain !== 1001 && activeChain !== 8217) {
                handleNetworkErrorModalOpen(true);
                return;
              }
            }

            if (isEthereumNetwork) {
              dispatch(
                getBalances({
                  account,
                  library,
                  chainId,
                }),
              );

              if (activeChainId[selectedNetwork] !== chainId) {
                handleNetworkErrorModalOpen(true);
                return;
              }
            }

            handleNetworkErrorModalOpen(false, { keepSelectedWallet: true });
            dispatch(
              setConnector({
                network: selectedNetwork,
                library,
              }),
            );
            dispatch(fetchUserProfile({ account: walletAccount }));
          }

          dispatch(fetchUserInfo({ account: walletAccount }));
          dispatch(readyToUpdate());
          handleConnectOff();

          onMenuClose?.();
        } catch (e) {
          handleUpdateToast('Auth fail', 'error', true);
          resetConnect();
        }
      })();
    }
  }, [
    isConnecting,
    account,
    library,
    chainId,
    selectedNetwork,
    selectedWalletProvider,
    solanaChainId,
    router,
    connected,
    publicKey,
    handleNetworkErrorModalOpen,
    deactivate,
    dispatch,
    resetConnect,
  ]);

  useEffect(() => {
    setIsEthereumProvider(!!window.ethereum);
    setIsMobile(checkMobileDevice());
    setIsIos(checkIos());

    return () => {
      setSelectedNetwork('polygon');
      setSelectedWalletProvider(null);
    };
  }, []);

  useEffect(
    () => () => {
      if (isOpen) {
        setIsClicked(true);
        handleCloseToast();
        setSelectedWalletProvider(null);
        handleNetworkErrorModalOpen(false);
        setIsConnecting(false);
      }
    },
    [isOpen],
  );

  useEffect(() => {
    const body = document.querySelector('body');

    if (!body) return;

    if (isModalOpen) {
      body.style.overflowY = 'hidden';
      return;
    }

    body.style.overflowY = 'auto';
  }, [dispatch, isModalOpen]);

  useEffect(() => {
    router.events.on('routeChangeStart', handleConnectOff);

    return () => {
      router.events.off('routeChangeStart', handleConnectOff);
    };
  }, [router]);

  if (isMdDown) {
    return (
      <>
        <WalletDrawer
          isOpen={isOpen}
          handleOpen={handleOpen}
          removeOnClosed={false}
          direction="bottom"
        >
          <WalletButtonGroup
            errorNetworkMessage={errorNetworkMessage}
            solanaWalletDownloadCheck={solanaWalletDownloadCheck}
            isEthereumProvider={isEthereumProvider}
            handleInstallMetaMask={handleInstallMetaMask}
            isMobile={isMobile}
            selectedWalletProvider={selectedWalletProvider}
            handleConnectWallet={handleConnectWallet}
            handleSolanaConnectWallet={handleSolanaConnectWallet}
            handleShowOpen={handleShowOpen}
            isClicked={isClicked}
          />
          <HelpBlock>
            <Button variant="text" onClick={() => handleConnectHelpOpen()}>
              <Typography type="p5">How to sign in?</Typography>
            </Button>
          </HelpBlock>
        </WalletDrawer>

        {active && selectedNetwork && (
          <NetworkAlert
            isOpen={isNetworkErrorModalOpen}
            handleOpen={handleNetworkErrorModalOpen}
            handleSignInModalClose={handleConnectOff}
            shouldLogoutOnClose={false}
          />
        )}

        <HowToSignInModal
          isOpen={isConnectHelpOpen}
          handleOpen={() => handleConnectHelpOpen()}
        />
      </>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={!isConnecting}
      width={{ xl: '360px', lg: '360px', md: '360px' }}
      handleOpen={handleOpen}
      hideCloseButton
    >
      <ModalContents>
        <SignInTitle>
          <Typography type={isMdDown ? 'h4' : 'h5'}>Sign in</Typography>
          <IconBox disabled={isConnecting} onClick={handleConnectOff}>
            <Icon name="close" size={32} />
          </IconBox>
        </SignInTitle>
        <Typography type="p5">
          Choose a wallet that you want to connect.
        </Typography>
        <WalletButtonGroup
          errorNetworkMessage={errorNetworkMessage}
          solanaWalletDownloadCheck={solanaWalletDownloadCheck}
          isEthereumProvider={isEthereumProvider}
          handleInstallMetaMask={handleInstallMetaMask}
          isMobile={isMobile}
          selectedWalletProvider={selectedWalletProvider}
          handleConnectWallet={handleConnectWallet}
          handleSolanaConnectWallet={handleSolanaConnectWallet}
          handleShowOpen={handleShowOpen}
          isClicked={isClicked}
        />

        <HelpBlock>
          <Button variant="text" onClick={() => handleConnectHelpOpen()}>
            <Typography type="p5">How to sign in?</Typography>
          </Button>
        </HelpBlock>
      </ModalContents>

      {active && selectedNetwork && (
        <NetworkAlert
          isOpen={isNetworkErrorModalOpen}
          handleOpen={handleNetworkErrorModalOpen}
          handleSignInModalClose={handleConnectOff}
          shouldLogoutOnClose={false}
        />
      )}

      <HowToSignInModal
        isOpen={isConnectHelpOpen}
        handleOpen={() => handleConnectHelpOpen()}
      />
    </Modal>
  );
};

export default SignInModal;
