import { useQuery } from 'react-query';
import styled from '@emotion/styled';
import { breakpoints, palette, spacing, Typography } from '@playdapp/ui';
import { getAlarm } from 'api/alarm';
import { useAppSelector } from 'store/hooks';
import { useCallback, useState } from 'react';
import Icon from '@/components/Icon';
import NotificationSetting from './NotificationSetting';

type Props = {
  title?: string;
  handleOpen?: () => void;
};

const HeaderBlock = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  height: 4.5rem;
  padding: ${spacing.m} 3rem;
  border-bottom: 1px solid ${palette.gray100};
  background-color: ${palette.white};
  z-index: 10;
  color: ${palette.dgray700};

  ${breakpoints.down('xl')} {
    height: 4rem;
    padding: ${spacing.s} 3rem;
  }

  ${breakpoints.down('md')} {
    height: 3.5rem;
    padding: ${spacing.s};
  }
`;

const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  padding: 0;
`;

const TitleBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Setting = styled.div`
  display: flex;
  align-items: center;
`;

const OpenMenuHeader = ({ title, handleOpen }: Props) => {
  const { account } = useAppSelector(({ wallet }) => wallet);

  const [isOpenSettingMenu, setIsOpenSettingMenu] = useState(false);

  const { data: alarmCountData } = useQuery(
    'getAlarmCount',
    async () => {
      if (account) {
        const res = await getAlarm({
          account,
          page: 0,
        });

        return res.data?.totalCount;
      }
      return false;
    },
    {
      enabled: !!account,
      cacheTime: 0,
    },
  );

  const handleOpenSettingMenu = useCallback(() => {
    setIsOpenSettingMenu(!isOpenSettingMenu);
  }, [isOpenSettingMenu]);

  return (
    <HeaderBlock>
      <CloseButton onClick={handleOpen}>
        <Icon name="chevron-left" size={14} />
      </CloseButton>

      {title === 'Notification' && account ? (
        <>
          <TitleBlock>
            <Typography type="p3">
              {title}&nbsp;&#40;{alarmCountData}&#41;
            </Typography>
          </TitleBlock>
          <Setting
            onClick={() => {
              setIsOpenSettingMenu(true);
            }}
          >
            <Icon name="setting" size={17} />
          </Setting>
        </>
      ) : (
        <TitleBlock>
          <Typography type="p3">{title}</Typography>
        </TitleBlock>
      )}

      {isOpenSettingMenu && (
        <NotificationSetting handleOpen={handleOpenSettingMenu} />
      )}
    </HeaderBlock>
  );
};

export default OpenMenuHeader;
