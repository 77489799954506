import styled from '@emotion/styled';
import { breakpoints, spacing } from '@playdapp/ui';

import ExploreMenuList from './ExploreMenuList';
import SearchBox from './SearchBox';

const ExploreMenuBlock = styled.div`
  width: 100%;
  box-shadow: none;
  background-color: #fff;
  z-index: 100;
  padding: ${spacing.m};

  & > div {
    margin-bottom: ${spacing.xl};
  }

  ${breakpoints.down('lg')} {
    min-height: 100vh;
  }
`;

const ExploreMenu = () => (
  <ExploreMenuBlock>
    <SearchBox />
    <ExploreMenuList />
  </ExploreMenuBlock>
);

export default ExploreMenu;
