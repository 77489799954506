import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { useDisconnect } from 'wagmi';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import useSolana from 'hooks/useSolana';
import useWeb3Info from 'hooks/useWeb3Info';
import { resetUserInfo } from 'store/user';
import { setPageNetwork } from 'store/page';
import { setConnector } from 'store/marketplace';
import { setConnector as setSolanaConnector } from 'store/marketplaceSolana';
import { resetWalletInfo } from 'store/wallet';
import { removeLoginInfo } from 'lib/auth';
import { activeChainId } from 'lib/network';

import type { NetworkName } from 'types/network';
import type { WalletProvider } from 'types/wallet';

type ReturnValue = {
  isSupported: boolean;
};

const useUpdateWallet = (): ReturnValue => {
  const [isSupported, setIsSupported] = useState(true);

  const dispatch = useAppDispatch();

  const { active } = useAppSelector(({ user }) => user);
  const { netWork: pageNetwork } = useAppSelector(({ page }) => page);
  const { library, chainId, provider } = useAppSelector(({ wallet }) => wallet);

  const router = useRouter();

  const { deactivate } = useWeb3React<Web3Provider>();
  const { disconnect: wagmiDisconnect } = useDisconnect();

  const { active: activeWallet, account } = useWeb3Info();
  const { connected, disconnect } = useSolana();

  const handleLogout = useCallback(() => {
    removeLoginInfo();
    dispatch(resetWalletInfo());
    dispatch(resetUserInfo());
    dispatch(setPageNetwork(null));
    deactivate();
    disconnect();
    wagmiDisconnect();
  }, [deactivate, dispatch]);

  useEffect(() => {
    const network =
      pageNetwork || (localStorage.getItem('PDMP_NETWORK') as NetworkName);
    const sign = localStorage.getItem('PDMP_SIGN') as string;
    const prevAccount = localStorage.getItem('PDMP_ADDRESS') as string;

    if ((active || connected) && (!network || !sign)) {
      handleLogout();
      return;
    }

    if (account && chainId && library && active && prevAccount !== account) {
      handleLogout();
      router.push('/');
      return;
    }

    if (
      (typeof chainId === 'number' || typeof chainId === 'string') &&
      typeof network === 'string'
    ) {
      setIsSupported(activeChainId[network] === chainId);
    }
  }, [
    account,
    router,
    chainId,
    connected,
    library,
    active,
    pageNetwork,
    dispatch,
    handleLogout,
  ]);
  useEffect(() => {
    if (typeof window.klaytn !== 'undefined') {
      window.klaytn.autoRefreshOnNetworkChange = false;
      const provider = localStorage.getItem('PDMP_WALLET_PROVIDER');

      const handleNetworkChange = (networkId: number) => {
        if (chainId !== networkId && provider === 'kaikas') {
          handleLogout();
          router.push('/');
        }
      };

      window.klaytn.on('networkChanged', handleNetworkChange);
      return () => {
        window.klaytn.removeListener('networkChanged', handleNetworkChange);
      };
    }
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.klaytn) {
        const handleAccountChange = () => {
          const provider = localStorage.getItem(
            'PDMP_WALLET_PROVIDER',
          ) as WalletProvider;

          if (provider === 'kaikas') {
            removeLoginInfo();
            dispatch(resetWalletInfo());
            dispatch(resetUserInfo());
            dispatch(setPageNetwork(null));
            router.push('/');
          }
        };
        window.klaytn.on('accountsChanged', handleAccountChange);
        return () => {
          window.klaytn.removeListener('accountsChanged', handleAccountChange);
        };
      }
    }
  });

  useEffect(() => {
    if (active && !activeWallet && !connected) {
      handleLogout();
    }
  }, [active, activeWallet, connected, handleLogout, provider]);

  useEffect(() => {
    const network =
      pageNetwork || (localStorage.getItem('PDMP_NETWORK') as NetworkName);

    if (isSupported && library) {
      dispatch(
        setConnector({
          network,
          library,
        }),
      );
    }

    if (connected) {
      dispatch(setSolanaConnector());
    }
  }, [connected, pageNetwork, isSupported, library, dispatch]);

  return { isSupported };
};

export default useUpdateWallet;
