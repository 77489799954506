import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import styled from '@emotion/styled';
import {
  breakpoints,
  fontSize,
  palette,
  spacing,
  Typography,
} from '@playdapp/ui';
import { useClickAway, useMedia } from 'react-use';
import debounce from 'lodash/debounce';
import { useQuery } from 'react-query';
import times from 'lodash/times';
import { Skeleton } from '@chakra-ui/react';
import axios from 'axios';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setQuery } from 'store/collections';
import { getBreakpointQuery } from 'lib/util';
import renderPlaceholderImage from 'lib/render-placeholder';
import resultSearch from 'api/graphql/resultSearch';
import type { NetworkName } from 'types/network';
import type { ResultSearchResponseData } from 'types/search';

import Icon from './Icon';
import Image from './Image';

type CollectionItemProps = {
  id: number;
  image: string;
  network: NetworkName;
  title: string;
};

type TokenItemProps = {
  tokenId: string;
  assetAddress: string;
  image: string;
  network: NetworkName;
  name: string;
  categoryImage: string;
  categoryTitle: string;
};

const SearchBoxBlock = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  min-width: 528px;
  ${breakpoints.down('xxl')} {
    min-width: 375px;
  }
  ${breakpoints.down('xl')} {
    min-width: 226px;
  }
`;

const SearchInputBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 528px;
  height: 2rem;
  padding-left: ${spacing.xxs};
  border-bottom: 1px solid ${palette.gray900};

  & > span {
    flex: none;
  }

  &:focus-within {
    border-bottom: 1px solid ${palette.dgray600};
  }

  ${breakpoints.down('lg')} {
    position: static;
    top: 0;
    right: 0;
    height: 39px;
    padding: 0;
    background-color: ${palette.white};
    border-bottom: 1px solid ${palette.dgray400};

    &:focus-within {
      border-bottom: 1px solid ${palette.dgray400};
    }
  }

  ${breakpoints.down('md')} {
    max-width: none;
    height: 2.25rem;
    border-radius: 8px;
    padding: ${spacing.xs} ${spacing.m};
    border: 1px solid ${palette.gray900};

    &:focus-within {
      border: none;
    }
  }
`;

const SearchInput = styled.input`
  outline: none;
  width: 100%;
  height: 1.5rem;
  margin-left: ${spacing.xs};
  background-color: transparent;
  ::placeholder {
    font-size: ${fontSize.p3};
  }

  ${breakpoints.down('lg')} {
    height: 1rem;
    margin-left: ${spacing.m};
    background-color: ${palette.white};
  }
`;

const ClearButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  padding: 0;
  margin-right: ${spacing.s};
`;

const SearchResultBlock = styled.div`
  position: absolute;
  top: 2rem;
  width: 100%;
  min-height: 192px;
  max-height: calc(70vh);
  background-color: ${palette.white};
  box-shadow: 0px 20px 36px rgba(76, 79, 110, 0.1);
  border-radius: 16px;
  z-index: 1;

  ${breakpoints.down('lg')} {
    position: static;
    box-shadow: none;

    & > div + div {
      margin-top: ${spacing.m};
    }
  }
`;

const SearchResultListGroup = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  min-height: 192px;
  max-height: calc(60vh);
  padding: 1.75rem ${spacing.xl};

  & > div + div {
    margin-top: ${spacing.l};
  }

  ${breakpoints.down('lg')} {
    overflow-x: hidden;
    padding: 0;
    margin-top: ${spacing.m};
  }

  ${breakpoints.down('md')} {
    min-height: calc(90vh - 160px);
    padding: 1.25rem 0;

    & > div + div {
      margin-top: ${spacing.l};
    }
  }

  ::-webkit-scrollbar {
    width: 10px;
    background-color: ${palette.white};
    border-radius: 0 16px 16px 0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${palette.gray400};
    border-radius: 8px;
  }
`;

const SearchResultList = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${spacing.l};
  border-bottom: 1px solid ${palette.gray400};

  & > span {
    margin-bottom: ${spacing.s};
    font-size: ${fontSize.p4};
    color: ${palette.gray900};
  }

  & > a + a {
    margin-top: ${spacing.s};
  }

  ${breakpoints.down('lg')} {
    padding: 0;
    border: none;
  }
`;

const SearchResultItemBlock = styled.a`
  display: flex;
  width: 100%;
  padding: ${spacing.xs};
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: ${palette.gray200};
  }
`;

const CollectionItemImageBlock = styled.div`
  width: 48px;
  height: 48px;

  ${breakpoints.down('lg')} {
    width: 36px;
    height: 36px;
  }

  & > span {
    border-radius: 50%;
    font-size: ${fontSize.p4};
  }
`;

const TokenItemImageBlock = styled.div`
  flex: none;
  width: 48px;
  height: 48px;
  ${breakpoints.down('lg')} {
    width: 36px;
    height: 36px;
  }

  span,
  & > img,
  video {
    flex: none;
    border-radius: 8px;
  }
`;

const SearchResultInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: ${spacing.xs};
`;

const SearchResultItemTitle = styled.div`
  display: flex;
`;

const SearchResultItemName = styled.div`
  display: -webkit-box;
  width: 100%;
  height: auto;
  margin-left: ${spacing.xxs};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  ${breakpoints.down('lg')} {
    width: 70vw;
  }

  ${breakpoints.down('md')} {
    width: 60vw;
  }
`;

const SearchResultItemTag = styled.div`
  display: flex;
  padding: ${spacing.xxs};
  background: ${palette.white};
  border: 1px solid #eee;
  border-radius: 10px;

  & > span {
    border-radius: 50%;
  }
`;

const SearchResuitItemTagName = styled.div`
  margin-left: ${spacing.xxs};
`;

const SkeletonBlock = styled.div`
  width: 100%;

  & > div + div {
    margin-top: ${spacing.s};
  }
`;
const NotFoundTextBlock = styled.div`
  padding: ${spacing.l};
  width: 100%;
  text-align: center;

  p {
    font-size: ${fontSize.p4};
  }
`;

const ErrorTextBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 192px;
  text-align: center;
  font-size: ${fontSize.p4};
`;

const SearchResultSkeleton = React.memo(() => (
  <SkeletonBlock>
    {times(6, (index) => (
      <Skeleton key={index} width="100%" height="70px" borderRadius="8px" />
    ))}
  </SkeletonBlock>
));

const CollectionItem = ({ id, image, network, title }: CollectionItemProps) => (
  <Link href={`/collection/${network}/${id}`} passHref>
    <SearchResultItemBlock>
      <CollectionItemImageBlock>
        <Image src={image} alt="result-item" width={32} height={32} />
      </CollectionItemImageBlock>
      <SearchResultInfo>
        <SearchResultItemTitle>
          <Icon name={network} size={16} />
          <SearchResultItemName>
            <Typography type="p4" component="p">
              {title}
            </Typography>
          </SearchResultItemName>
        </SearchResultItemTitle>
      </SearchResultInfo>
    </SearchResultItemBlock>
  </Link>
);

const TokenItem = ({
  tokenId,
  image,
  network,
  name,
  categoryImage,
  categoryTitle,
  assetAddress,
}: TokenItemProps) => {
  const isLargeScreen = useMedia(
    getBreakpointQuery(breakpoints.up('lg')),
    true,
  );
  const [isVideoError, setIsVideoError] = useState(false);
  const [isImageError, setIsImageError] = useState(false);
  // image 검사
  useEffect(() => {
    if (typeof image === 'string') {
      if (!/\.(gif|jpe?g|tiff?|png|webp|bmp|mp4|mov)$/i.test(image)) {
        (async () => {
          const res = await axios.get(image);
          if (!/image/i.test(res.headers['content-type'])) {
            setIsImageError(true);
          }
        })();
      }
    }
  }, [image]);
  return (
    <Link href={`/item/${network}/${assetAddress}/${tokenId}`} passHref>
      <SearchResultItemBlock>
        <TokenItemImageBlock>
          {(!image || isImageError || isVideoError) && (
            <Image
              src="https://images.playdapp.com/marketplace/collections/placeholder2v.png"
              alt="card-thumb"
              layout="fill"
            />
          )}
          {!isVideoError &&
          !isImageError &&
          typeof image === 'string' &&
          /.(mp4|mov)$/.test(image) ? (
            <video
              src={image}
              muted
              autoPlay
              loop
              playsInline
              onError={() => {
                setIsVideoError(true);
              }}
            />
          ) : (
            <Image
              src={image}
              alt="item"
              width={isLargeScreen ? 48 : 36}
              height={isLargeScreen ? 48 : 36}
              onError={renderPlaceholderImage}
            />
          )}
        </TokenItemImageBlock>
        <SearchResultInfo>
          <SearchResultItemTitle>
            <Icon name={network} size={isLargeScreen ? 20 : 18} />
            <SearchResultItemName>
              <Typography type="p4" component="p">
                {name}
              </Typography>
            </SearchResultItemName>
          </SearchResultItemTitle>
          <SearchResultItemTag>
            <Image
              src={categoryImage}
              alt=""
              width={14}
              height={14}
              onError={renderPlaceholderImage}
            />
            <SearchResuitItemTagName>
              <Typography type="p6" component="p">
                {categoryTitle}
              </Typography>
            </SearchResuitItemTagName>
          </SearchResultItemTag>
        </SearchResultInfo>
      </SearchResultItemBlock>
    </Link>
  );
};

const SearchResult = () => {
  const isLargeScreen = useMedia(
    getBreakpointQuery(breakpoints.up('lg')),
    true,
  );
  const { query } = useAppSelector(({ collections }) => collections);
  const { isFetching, data, error } = useQuery<
    void,
    Error,
    ResultSearchResponseData,
    [string]
  >([query], () => resultSearch({ query }), { refetchOnWindowFocus: false });
  return (
    <SearchResultBlock>
      <SearchResultListGroup>
        {isFetching && <SearchResultSkeleton />}
        {!isFetching && data && (
          <>
            <SearchResultList>
              <Typography type={isLargeScreen ? 'p2' : 'p3'} color="dgray300">
                Collection
              </Typography>
              {!data.collections.length && (
                <NotFoundTextBlock>
                  <Typography
                    type={isLargeScreen ? 'p2' : 'p3'}
                    color="gray700"
                    component="p"
                  >
                    No search results found
                  </Typography>
                </NotFoundTextBlock>
              )}
              {data.collections.length > 0 &&
                data.collections.map((item, index) => (
                  <CollectionItem
                    key={`${item.categoryId}-${index}`}
                    id={item.categoryId}
                    image={item.thumbnailUrl}
                    network={item.network}
                    title={item.title}
                  />
                ))}
            </SearchResultList>
            <SearchResultList>
              <Typography type={isLargeScreen ? 'p2' : 'p3'} color="dgray300">
                Item
              </Typography>
              {!data.tokens.length && (
                <NotFoundTextBlock>
                  <Typography
                    type={isLargeScreen ? 'p2' : 'p3'}
                    color="gray700"
                    component="p"
                  >
                    No search results found
                  </Typography>
                </NotFoundTextBlock>
              )}
              {data.tokens.length > 0 &&
                data.tokens.map((item, index) => (
                  <TokenItem
                    key={`${item.tokenId}-${index}`}
                    tokenId={item.tokenId}
                    assetAddress={item.assetAddress}
                    image={item.imageUri}
                    network={item.network}
                    name={item.name}
                    categoryImage={item.collectionInfo.thumbnailUrl}
                    categoryTitle={item.collectionInfo.title}
                  />
                ))}
            </SearchResultList>
          </>
        )}
        {!isFetching && error && !data && (
          <ErrorTextBlock>
            <Typography
              type={isLargeScreen ? 'p2' : 'p3'}
              color="gray700"
              component="p"
            >
              Search result request failed
            </Typography>
          </ErrorTextBlock>
        )}
      </SearchResultListGroup>
    </SearchResultBlock>
  );
};

const SearchBox = () => {
  const router = useRouter();

  const isLargeScreen = useMedia(
    getBreakpointQuery(breakpoints.up('lg')),
    true,
  );
  const isMdDown = useMedia(getBreakpointQuery(breakpoints.down('md')), true);

  const [isFocus, setIsFocus] = useState(false);
  const [inputQuery, setInputQuery] = useState('');
  const [isMounted, setIsMounted] = useState(false);

  const containerElement = useRef(null);

  const dispatch = useAppDispatch();
  const { query } = useAppSelector(({ collections }) => collections);

  useClickAway(containerElement, () => {
    setIsFocus(false);
  });

  const inputFocusEventHandlers = useMemo(
    () => ({
      onFocus: () => setIsFocus(true),
    }),
    [],
  );

  const debouncedHandleChange = useMemo(
    () =>
      debounce((currentInput: string) => {
        dispatch(setQuery(currentInput));
      }, 500),
    [dispatch],
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const replaceValue = value.replace(/[^\w\s]/gi, '');
    setInputQuery(replaceValue);
    debouncedHandleChange(replaceValue);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && query) {
      router.push(`/collection/all/all?search=${inputQuery}`);
    }
  };

  const handleClear = () => {
    dispatch(setQuery(''));
    setInputQuery('');
  };

  useEffect(() => {
    if (
      !isMounted &&
      /\/collection\/(all|ethereum|polygon)\/(all|[0-9]{1,})\?search=/.test(
        router.asPath,
      )
    ) {
      const search = router.query?.search as string;
      if (search) {
        const replaceValue = search.replace(/[^\w\s]/gi, '');
        setInputQuery(replaceValue);
        dispatch(setQuery(replaceValue));
        setIsMounted(true);
      }
    }
  }, [isMounted, router, dispatch]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (isFocus) {
        setIsFocus(false);
      }
      if (!/\/collection\/(all|ethereum|polygon)\/all\?search=/.test(url)) {
        dispatch(setQuery(''));
      }
    };
    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router, isFocus, dispatch]);

  useEffect(() => {
    if (isMounted && !query) {
      setInputQuery('');
    }
  }, [isMounted, query]);

  return (
    <SearchBoxBlock ref={containerElement}>
      <SearchInputBox>
        {!isMdDown && (
          <Icon
            name={isFocus ? 'search-black' : 'search'}
            size={isLargeScreen ? 20 : 16}
          />
        )}
        <SearchInput
          onChange={handleChange}
          placeholder="Collection, Item"
          value={inputQuery}
          onKeyDown={handleKeyDown}
          {...inputFocusEventHandlers}
        />
        {query && (
          <ClearButton onClick={handleClear}>
            <Icon name="close-circle-gray" size={isMdDown ? 20 : 13.33} />
          </ClearButton>
        )}

        {isMdDown && <Icon name="search-black" size={18.71} />}
      </SearchInputBox>

      {((isLargeScreen && isFocus && query) || (!isLargeScreen && query)) && (
        <SearchResult />
      )}
    </SearchBoxBlock>
  );
};

export default SearchBox;
