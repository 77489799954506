import styled from '@emotion/styled';
import { spacing, palette } from '@playdapp/ui';
import Icon from './Icon';
import SearchBox2 from './SearchBox2';

type Props = {
  handleOpen: () => void;
};

const HeaderMenuBlock = styled.div`
  overflow-y: none;
  position: fixed;
  inset: 0;
  width: 100%;
  background-color: ${palette.white};
  z-index: 1000;

  min-height: 100vh;
  min-height: -webkit-fill-available;
`;

const SearchBlock = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 3.5rem;
  padding: 0 ${spacing.m};
  border-bottom: 1px solid ${palette.gray600};
`;

const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  padding: 0;
`;

const SearchMenu2 = ({ handleOpen }: Props) => (
  <HeaderMenuBlock>
    <SearchBlock>
      <CloseButton onClick={handleOpen}>
        <Icon name="chevron-left" size={14} />
      </CloseButton>
      <SearchBox2 />
    </SearchBlock>
  </HeaderMenuBlock>
);

export default SearchMenu2;
