import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import styled from '@emotion/styled';
import {
  breakpoints,
  fontSize,
  fontStyle,
  palette,
  spacing,
  Typography,
} from '@playdapp/ui';
import { useClickAway, useMedia, useWindowScroll } from 'react-use';
import debounce from 'lodash/debounce';
import { useQuery } from 'react-query';
import times from 'lodash/times';
import { Skeleton } from '@chakra-ui/react';
import axios from 'axios';
import { differenceInMilliseconds, milliseconds } from 'date-fns';
import { DebouncedFunc } from 'lodash';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setQuery } from 'store/collections';
import { setSelectSearchInfoColor, setIsSelectFocus } from 'store/header';
import { getBreakpointQuery } from 'lib/util';
import renderPlaceholderImage from 'lib/render-placeholder';
import resultSearch from 'api/graphql/resultSearch';
import type { NetworkName } from 'types/network';
import type { ResultSearchResponseData } from 'types/search';

import Icon from './Icon';
import Image from './Image';

type CollectionItemProps = {
  id: number;
  image: string;
  network: NetworkName;
  title: string;
  addRecentSearch: (query: string) => void;
};

type TokenItemProps = {
  tokenId: string;
  assetAddress: string;
  image: string;
  network: NetworkName;
  name: string;
  categoryImage: string;
  categoryTitle: string;
};

type ResultProps = {
  addRecentSearch: (query: string) => void;
};

type DefaultResult = {
  id: number;
  result: string;
  timestamp: number;
};

type DefaultResultProps = {
  recentSearch: DefaultResult[];
  handleSearch: (title: string) => void;
  handleDelete: (id: number) => void;
};

type ModalProps = {
  recentSearch: DefaultResult[];
  debouncedHandleChange: DebouncedFunc<(currentInput: string) => void>;
  addRecentSearch: (query: string) => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleSearch: (title: string) => void;
  handleDelete: (id: number) => void;
};

const SearchBoxBlock = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  min-width: 528px;
  border: 1px solid #4b4d5826;
  border-radius: 8px;

  ${breakpoints.down('xxl')} {
    min-width: 468px;
  }

  ${breakpoints.down('xl')} {
    min-width: 329px;
  }

  ${breakpoints.down('md')} {
    min-width: 100%;
    border: none;
  }
`;

const SearchModalBlock = styled.div`
  position: absolute;
  top: 3.5rem;
  right: 10%;
  width: 400px;
  padding: ${spacing.xl};
  background-color: ${palette.white};
  box-shadow: 0px 20px 32px rgba(76, 79, 110, 0.1);
  border-radius: 16px;
  z-index: 2;
`;

const SearchInputBox = styled.div<{
  isSelectAdvertise: boolean;
  isMainPath: boolean;
}>`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 528px;
  padding: 9px 12px;
  gap: 12px;
  background: ${({ isSelectAdvertise, isMainPath }) =>
    isSelectAdvertise && isMainPath
      ? 'rgba(255, 255, 255, 0.2);'
      : 'rgba(255, 255, 255, 0.3)'};
  backdrop-filter: blur(12px);
  border-radius: 8px;

  & > span {
    flex: none;
    width: 24px;
    height: 24px;
  }

  ${breakpoints.down('lg')} {
    position: static;
    top: 0;
    right: 0;
    height: 39px;
    padding: 0;
    border-radius: 0;
    background-color: ${palette.white};
    border-bottom: 1px solid ${palette.dgray400};

    &:focus-within {
      border-bottom: 1px solid ${palette.dgray400};
    }
  }

  ${breakpoints.down('md')} {
    max-width: none;
    height: 3.5rem;
    padding: ${spacing.s} ${spacing.xl};
    background-color: transparent;
    border: none;
    backdrop-filter: none;
    z-index: 2;

    &:focus-within {
      border: none;
    }
  }
`;

const SearchInput = styled.input<{
  clickInputType: string;
}>`
  outline: none;
  width: 100%;
  height: 1.5rem;
  background-color: transparent;
  padding-left: ${spacing.s};
  color: ${({ clickInputType }) => clickInputType};

  ${breakpoints.down('lg')} {
    height: auto;
    margin-left: ${spacing.m};
    background-color: ${palette.white};
  }
`;

const ClearButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  padding: 0;
  margin-right: ${spacing.s};
`;

const SearchResultBlock = styled.div`
  position: absolute;
  top: 3rem;
  width: 100%;
  min-height: 192px;
  max-height: calc(70vh);
  background-color: ${palette.white};
  box-shadow: 0px 20px 36px rgba(76, 79, 110, 0.1);
  border-radius: 16px;
  z-index: 1;

  ${breakpoints.down('lg')} {
    position: static;
    box-shadow: none;

    & > div + div {
      margin-top: ${spacing.m};
    }
  }

  ${breakpoints.down('md')} {
    position: absolute;
    top: 3.5rem;
    box-shadow: none;
    min-height: calc(100vh - 56px);

    & > div + div {
      margin-top: ${spacing.m};
    }
  }
`;

const SearchDefaultResultBlock = styled.div`
  position: absolute;
  top: 3rem;
  width: 100%;
  min-height: 112px;
  max-height: calc(60vh);
  padding: 1.5rem;
  background-color: ${palette.white};
  box-shadow: 0px 20px 36px rgba(76, 79, 110, 0.1);
  border-radius: 16px;
  z-index: 1;

  ${breakpoints.down('lg')} {
    position: static;
    margin-top: ${spacing.m};
    padding: 0;
    box-shadow: none;
  }

  ${breakpoints.down('md')} {
    position: absolute;
    top: 3.5rem;
    min-height: calc(90vh - 160px);
    margin-top: 0;
    padding: 1.25rem 0;
    box-shadow: none;

    & > div + div {
      margin-top: ${spacing.m};
    }
  }
`;

const PopularItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-top: ${spacing.m};
`;

const PopularItem = styled.div`
  display: inline-block;
  padding: 0.375rem 0.75rem;
  font-size: 0.75rem;
  color: ${palette.dgray600};
  background-color: ${palette.gray300};
  border-radius: 8px;

  :hover {
    transition: 0.2s;
    background-color: ${palette.gray600};
    cursor: pointer;
  }
`;

const RecentItemBlock = styled.div`
  margin-top: ${spacing.m};
  padding-top: ${spacing.m};
  border-top: 1px solid ${palette.gray400};

  div:first-of-type {
    margin-top: 0.25rem;
  }
`;

const RecentItemWrapper = styled.div`
  overflow-x: none;
  overflow-y: auto;
  max-height: 225px;

  ::-webkit-scrollbar {
    width: 10px;
    background-color: ${palette.white};
    border-radius: 0 16px 16px 0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${palette.gray400};
    border-radius: 8px;
  }

  ${breakpoints.down('md')} {
    max-height: none;
  }
`;

const RecentItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${spacing.xs} ${spacing.s};

  :hover {
    transition: 0.2s;
    background-color: ${palette.gray200};
    cursor: pointer;
  }
`;

const RecentItemName = styled.span`
  width: 100%;
  ${fontStyle('p4')};
  color: ${palette.dgray600};
`;

const DeleteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${spacing.xxs};

  :hover {
    transition: 0.2s;
    background-color: ${palette.gray400};
    cursor: pointer;
  }
`;

const SearchResultListGroup = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  min-height: 192px;
  max-height: calc(60vh);
  padding: 1.75rem ${spacing.xl};

  & > div + div {
    margin-top: ${spacing.l};
  }

  ${breakpoints.down('lg')} {
    overflow-x: hidden;
    padding: 0;
    margin-top: ${spacing.m};
  }

  ${breakpoints.down('md')} {
    min-height: calc(90vh - 160px);
    padding: 1.25rem 0;

    & > div + div {
      margin-top: ${spacing.l};
    }
  }

  ::-webkit-scrollbar {
    width: 10px;
    background-color: ${palette.white};
    border-radius: 0 16px 16px 0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${palette.gray400};
    border-radius: 8px;
  }
`;

const SearchResultList = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${spacing.l};
  border-bottom: 1px solid ${palette.gray400};

  & > span {
    margin-bottom: ${spacing.s};
    font-size: ${fontSize.p4};
    color: ${palette.gray900};
  }

  & > a + a {
    margin-top: ${spacing.s};
  }

  ${breakpoints.down('lg')} {
    padding: 0;
    border: none;
  }
`;

const SearchResultItemBlock = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${spacing.xs};
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${palette.gray200};
  }
`;

const CollectionItemImageBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;

  ${breakpoints.down('lg')} {
    width: 36px;
    height: 36px;
  }

  & > span {
    border-radius: 50%;
    font-size: ${fontSize.p4};
  }
`;

const TokenItemImageBlock = styled.div`
  flex: none;
  width: 48px;
  height: 48px;

  ${breakpoints.down('lg')} {
    width: 36px;
    height: 36px;
  }

  span,
  & > img,
  video {
    flex: none;
    border-radius: 8px;
  }
`;

const SearchResultInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: ${spacing.xs};
`;

const SearchResultItemTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.xxs};

  span {
    min-width: 16px;
  }
`;

const SearchResultItemName = styled.div`
  display: -webkit-box;
  width: 100%;
  height: auto;
  margin-left: ${spacing.xxs};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  ${breakpoints.down('lg')} {
    width: 70vw;
  }

  ${breakpoints.down('md')} {
    width: 60vw;
  }
`;

const SearchResultItemTag = styled.div`
  display: flex;
  padding: ${spacing.xxs};
  background: ${palette.white};
  border: 1px solid #eee;
  border-radius: 10px;

  & > span {
    border-radius: 50%;
  }
`;

const SearchResuitItemTagName = styled.div`
  margin-left: ${spacing.xxs};
`;

const SkeletonBlock = styled.div`
  width: 100%;

  & > div + div {
    margin-top: ${spacing.s};
  }
`;

const NotFoundTextBlock = styled.div`
  padding: ${spacing.l};
  width: 100%;
  text-align: center;

  p {
    font-size: ${fontSize.p4};
  }
`;

const ErrorTextBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 192px;
  text-align: center;
  font-size: ${fontSize.p4};
`;

const SearchResultSkeleton = React.memo(() => (
  <SkeletonBlock>
    {times(6, (index) => (
      <Skeleton key={index} width="100%" height="70px" borderRadius="8px" />
    ))}
  </SkeletonBlock>
));

const CollectionItem = ({
  id,
  image,
  network,
  title,
  addRecentSearch,
}: CollectionItemProps) => (
  <Link href={`/collections/${network}/${id}`} passHref>
    <SearchResultItemBlock
      onClick={() => {
        addRecentSearch(title);
      }}
    >
      <CollectionItemImageBlock>
        <Image src={image} alt="result-item" width={32} height={32} />
      </CollectionItemImageBlock>
      <SearchResultInfo>
        <SearchResultItemTitle>
          <Icon name={network} size={16} />
          <SearchResultItemName>
            <Typography type="p4" component="p">
              {title}
            </Typography>
          </SearchResultItemName>
        </SearchResultItemTitle>
      </SearchResultInfo>
    </SearchResultItemBlock>
  </Link>
);

const TokenItem = ({
  tokenId,
  image,
  network,
  name,
  categoryImage,
  categoryTitle,
  assetAddress,
}: TokenItemProps) => {
  const isLargeScreen = useMedia(
    getBreakpointQuery(breakpoints.up('lg')),
    true,
  );

  const [isVideoError, setIsVideoError] = useState(false);
  const [isImageError, setIsImageError] = useState(false);

  // image 검사
  useEffect(() => {
    if (typeof image === 'string') {
      if (!/\.(gif|jpe?g|tiff?|png|webp|bmp|mp4|mov)$/i.test(image)) {
        (async () => {
          const res = await axios.get(image);

          if (!/image/i.test(res.headers['content-type'])) {
            setIsImageError(true);
          }
        })();
      }
    }
  }, [image]);

  return (
    <Link href={`/item/${network}/${assetAddress}/${tokenId}`} passHref>
      <SearchResultItemBlock>
        <TokenItemImageBlock>
          {(!image || isImageError || isVideoError) && (
            <Image
              src="https://images.playdapp.com/marketplace/collections/placeholder2v.png"
              alt="card-thumb"
              layout="fill"
            />
          )}
          {!isVideoError &&
          !isImageError &&
          typeof image === 'string' &&
          /.(mp4|mov)$/.test(image) ? (
            <video
              src={image}
              muted
              autoPlay
              loop
              playsInline
              onError={() => {
                setIsVideoError(true);
              }}
            />
          ) : (
            <Image
              src={image}
              alt="item"
              width={isLargeScreen ? 48 : 36}
              height={isLargeScreen ? 48 : 36}
              onError={renderPlaceholderImage}
            />
          )}
        </TokenItemImageBlock>
        <SearchResultInfo>
          <SearchResultItemTitle>
            <Icon name={network} size={isLargeScreen ? 20 : 18} />
            <SearchResultItemName>
              <Typography type="p4" component="p">
                {name}
              </Typography>
            </SearchResultItemName>
          </SearchResultItemTitle>
          <SearchResultItemTag>
            <Image
              src={categoryImage}
              alt=""
              width={14}
              height={14}
              onError={renderPlaceholderImage}
            />
            <SearchResuitItemTagName>
              <Typography type="p6" component="p">
                {categoryTitle}
              </Typography>
            </SearchResuitItemTagName>
          </SearchResultItemTag>
        </SearchResultInfo>
      </SearchResultItemBlock>
    </Link>
  );
};

const SearchDefaultResult = ({
  recentSearch,
  handleSearch,
  handleDelete,
}: DefaultResultProps) => {
  const POPULAR_LIST = [
    {
      title: 'members',
    },
    {
      title: 'tournament',
    },
    {
      title: 'AWTG',
    },
    {
      title: 'LOK',
    },
    {
      title: 'holy spirit',
    },
  ];

  return (
    <SearchDefaultResultBlock>
      <Typography type="p4" color="gray900">
        Popular
      </Typography>
      <PopularItemWrapper>
        {POPULAR_LIST.map(({ title }) => (
          <PopularItem
            key={title}
            onClick={() => {
              handleSearch(title);
            }}
          >
            {title}
          </PopularItem>
        ))}
      </PopularItemWrapper>

      {recentSearch.length > 0 && (
        <RecentItemBlock>
          <Typography type="p4" color="gray900">
            Recent
          </Typography>

          <RecentItemWrapper>
            {recentSearch.map((item) => (
              <RecentItem key={item.id}>
                <RecentItemName
                  onClick={() => {
                    handleSearch(item.result);
                  }}
                >
                  {item.result}
                </RecentItemName>
                <DeleteButton
                  onClick={() => {
                    handleDelete(item.id);
                  }}
                >
                  <Icon name="close-gray" width={8} height={8} />
                </DeleteButton>
              </RecentItem>
            ))}
          </RecentItemWrapper>
        </RecentItemBlock>
      )}
    </SearchDefaultResultBlock>
  );
};

const SearchResult = ({ addRecentSearch }: ResultProps) => {
  const isLargeScreen = useMedia(
    getBreakpointQuery(breakpoints.up('lg')),
    true,
  );

  const { query } = useAppSelector(({ collections }) => collections);

  const { isFetching, data, error } = useQuery<
    void,
    Error,
    ResultSearchResponseData,
    [string]
  >([query], () => resultSearch({ query }), { refetchOnWindowFocus: false });

  return (
    <SearchResultBlock>
      <SearchResultListGroup>
        {isFetching && <SearchResultSkeleton />}
        {!isFetching && data && (
          <>
            <SearchResultList>
              <Typography type={isLargeScreen ? 'p2' : 'p3'} color="dgray300">
                Collection
              </Typography>
              {!data.collections.length && (
                <NotFoundTextBlock>
                  <Typography
                    type={isLargeScreen ? 'p2' : 'p3'}
                    color="gray700"
                    component="p"
                  >
                    No search results found
                  </Typography>
                </NotFoundTextBlock>
              )}
              {data.collections.length > 0 &&
                data.collections.map((item, index) => (
                  <CollectionItem
                    key={`${item.categoryId}-${index}`}
                    id={item.categoryId}
                    image={item.thumbnailUrl}
                    network={item.network}
                    title={item.title}
                    addRecentSearch={addRecentSearch}
                  />
                ))}
            </SearchResultList>
            <SearchResultList>
              <Typography type={isLargeScreen ? 'p2' : 'p3'} color="dgray300">
                Item
              </Typography>
              {!data.tokens.length && (
                <NotFoundTextBlock>
                  <Typography
                    type={isLargeScreen ? 'p2' : 'p3'}
                    color="gray700"
                    component="p"
                  >
                    No search results found
                  </Typography>
                </NotFoundTextBlock>
              )}
              {data.tokens.length > 0 &&
                data.tokens.map((item, index) => (
                  <TokenItem
                    key={`${item.tokenId}-${index}`}
                    tokenId={item.tokenId}
                    assetAddress={item.assetAddress}
                    image={item.imageUri}
                    network={item.network}
                    name={item.name}
                    categoryImage={item.collectionInfo.thumbnailUrl}
                    categoryTitle={item.collectionInfo.title}
                  />
                ))}
            </SearchResultList>
          </>
        )}
        {!isFetching && error && !data && (
          <ErrorTextBlock>
            <Typography
              type={isLargeScreen ? 'p2' : 'p3'}
              color="gray700"
              component="p"
            >
              Search result request failed
            </Typography>
          </ErrorTextBlock>
        )}
      </SearchResultListGroup>
    </SearchResultBlock>
  );
};

const SearchModal = ({
  recentSearch,
  debouncedHandleChange,
  addRecentSearch,
  handleKeyDown,
  handleSearch,
  handleDelete,
}: ModalProps) => {
  const router = useRouter();

  const [isFocus, setIsFocus] = useState(false);
  const [inputQuery, setInputQuery] = useState('');
  const [isMounted, setIsMounted] = useState(false);

  const containerElement = useRef(null);

  const dispatch = useAppDispatch();
  const { query } = useAppSelector(({ collections }) => collections);
  const { isSelectAdvertise } = useAppSelector(({ advertise }) => advertise);

  const isMainPath = router.asPath === '/';
  useClickAway(containerElement, () => {
    setIsFocus(false);
  });

  const inputFocusEventHandlers = useMemo(
    () => ({
      onFocus: () => setIsFocus(true),
    }),
    [],
  );

  const { y } = useWindowScroll();
  const scrollDown = y > 1;

  const inputStyleType =
    isSelectAdvertise && isMainPath && !scrollDown ? 'white' : 'black';
  const focusInputType = isFocus ? 'black' : palette.gray800;
  const clickInputType = isFocus ? inputStyleType : focusInputType;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    const replaceValue = value.replace(/[^\w\s]/gi, '');
    setInputQuery(replaceValue);
    debouncedHandleChange(replaceValue);
  };

  const handleClear = () => {
    dispatch(setQuery(''));
    setInputQuery('');
  };

  useEffect(() => {
    if (
      !isMounted &&
      /\/collection\/(all|ethereum|polygon)\/(all|[0-9]{1,})\?search=/.test(
        router.asPath,
      )
    ) {
      const search = router.query?.search as string;
      if (search) {
        const replaceValue = search.replace(/[^\w\s]/gi, '');
        setInputQuery(replaceValue);
        dispatch(setQuery(replaceValue));
        setIsMounted(true);
      }
    }
  }, [isMounted, router, dispatch]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (isFocus) {
        setIsFocus(false);
      }
      if (!/\/collection\/(all|ethereum|polygon)\/all\?search=/.test(url)) {
        dispatch(setQuery(''));
      }
    };
    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router, isFocus, dispatch]);

  useEffect(() => {
    if (isMounted && !query) {
      setInputQuery('');
    }
  }, [isMounted, query]);

  return (
    <SearchModalBlock ref={containerElement}>
      <SearchInputBox
        isSelectAdvertise={isSelectAdvertise}
        isMainPath={isMainPath}
      >
        <Icon name={isFocus ? 'search-black' : 'search'} size={20} />

        <SearchInput
          onChange={handleChange}
          placeholder="Collection, Item"
          value={inputQuery}
          onKeyDown={handleKeyDown}
          clickInputType={clickInputType}
          {...inputFocusEventHandlers}
        />
        {query && (
          <ClearButton onClick={handleClear}>
            <Icon name="close-circle-gray" size={13.33} />
          </ClearButton>
        )}
      </SearchInputBox>

      {!query && (
        <SearchDefaultResult
          recentSearch={recentSearch}
          handleSearch={handleSearch}
          handleDelete={handleDelete}
        />
      )}

      {query && <SearchResult addRecentSearch={addRecentSearch} />}
    </SearchModalBlock>
  );
};

const SearchBox2 = () => {
  const router = useRouter();

  const isLargeScreen = useMedia(
    getBreakpointQuery(breakpoints.up('lg')),
    true,
  );
  const isLgScreen = useMedia(getBreakpointQuery(breakpoints.down('lg')), true);
  const isMdDown = useMedia(getBreakpointQuery(breakpoints.down('md')), true);

  const [recentSearch, setRecentSearch] = useState<DefaultResult[]>([]);
  const [isFocus, setIsFocus] = useState(false);
  const [inputQuery, setInputQuery] = useState('');
  const [isMounted, setIsMounted] = useState(false);

  const containerElement = useRef(null);

  const dispatch = useAppDispatch();
  const { query } = useAppSelector(({ collections }) => collections);
  const { isSelectAdvertise } = useAppSelector(({ advertise }) => advertise);
  const { selectSearchInfoColor } = useAppSelector(({ header }) => header);

  const isMainPath = router.asPath === '/';

  const { y } = useWindowScroll();
  const scrollDown = y > 1;

  const inputStyleType =
    isSelectAdvertise && isMainPath && !scrollDown ? 'white' : 'black';
  const focusInputType = isFocus ? 'black' : palette.gray800;
  const clickInputType = isFocus ? inputStyleType : focusInputType;

  const handleSelectSearchInfo = () => {
    dispatch(setSelectSearchInfoColor(clickInputType));
    dispatch(setIsSelectFocus(isFocus));
  };

  useClickAway(containerElement, () => {
    setIsFocus(false);
  });

  const inputFocusEventHandlers = useMemo(
    () => ({
      onFocus: () => setIsFocus(true),
    }),
    [],
  );

  const debouncedHandleChange = useMemo(
    () =>
      debounce((currentInput: string) => {
        dispatch(setQuery(currentInput));
      }, 500),
    [dispatch],
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const replaceValue = value.replace(/[^\w\s]/gi, '');
    setInputQuery(replaceValue);
    debouncedHandleChange(replaceValue);
  };

  const addRecentSearch = (query: string) => {
    setInputQuery(query);
    debouncedHandleChange(query);

    if (recentSearch && recentSearch.length > 0) {
      const result = recentSearch.find((result) => result.result === query);

      if (result) {
        result.timestamp = Date.now();

        router.push(`/nfts/all/all?search=${result?.result}`);
      } else {
        setRecentSearch([
          {
            id: recentSearch[recentSearch.length - 1].id + 1,
            result: query,
            timestamp: Date.now(),
          },
          ...recentSearch,
        ]);
      }
    }

    if (recentSearch && recentSearch.length === 0) {
      setRecentSearch([
        {
          id: 1,
          result: query,
          timestamp: Date.now(),
        },
        ...recentSearch,
      ]);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && query) {
      setRecentSearch(recentSearch);
      addRecentSearch(query);

      router.push(`/nfts/all/all?search=${inputQuery}`);
    }
  };

  const handleClear = () => {
    dispatch(setQuery(''));
    setInputQuery('');
  };

  const handleSearch = (title: string) => {
    setRecentSearch(recentSearch);
    addRecentSearch(title);

    router.push(`/nfts/all/all?search=${title}`);
  };

  const handleDelete = (id: number) => {
    if (recentSearch) {
      setRecentSearch(recentSearch.filter((result) => result.id !== id));

      if (recentSearch.filter((result) => result.id !== id).length === 0) {
        localStorage.removeItem('PDMP_RECENT_SEARCH');
      }
    }
  };

  useEffect(() => {
    setRecentSearch([]);

    const results = JSON.parse(
      localStorage.getItem('PDMP_RECENT_SEARCH') || '[]',
    );

    const now = Date.now();

    setRecentSearch(
      results.filter(
        (result: DefaultResult) =>
          differenceInMilliseconds(
            now,
            +result.timestamp + milliseconds({ months: 1 }),
          ) < 0,
      ),
    );
  }, []);

  useEffect(() => {
    if (recentSearch.length > 0) {
      localStorage.setItem('PDMP_RECENT_SEARCH', JSON.stringify(recentSearch));
    }
  }, [recentSearch]);

  useEffect(() => {
    if (
      !isMounted &&
      /\/collection\/(all|ethereum|polygon)\/(all|[0-9]{1,})\?search=/.test(
        router.asPath,
      )
    ) {
      const search = router.query?.search as string;
      if (search) {
        const replaceValue = search.replace(/[^\w\s]/gi, '');
        setInputQuery(replaceValue);

        dispatch(setQuery(replaceValue));
        setIsMounted(true);
      }
    }
  }, [isMounted, router, dispatch]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (isFocus) {
        setIsFocus(false);
      }
      if (!/\/collection\/(all|ethereum|polygon)\/all\?search=/.test(url)) {
        dispatch(setQuery(''));
      }
    };
    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router, isFocus, dispatch]);

  useEffect(() => {
    if (isMounted && !query) {
      setInputQuery('');
    }
  }, [isMounted, query]);

  useEffect(() => {
    handleSelectSearchInfo();
  }, [isFocus, focusInputType, clickInputType]);

  return (
    <>
      {isLgScreen && !isMdDown && (
        <SearchModal
          recentSearch={recentSearch}
          addRecentSearch={addRecentSearch}
          debouncedHandleChange={debouncedHandleChange}
          handleKeyDown={handleKeyDown}
          handleSearch={handleSearch}
          handleDelete={handleDelete}
        />
      )}
      {(isLargeScreen || isMdDown) && (
        <SearchBoxBlock ref={containerElement}>
          <SearchInputBox
            isSelectAdvertise={isSelectAdvertise}
            isMainPath={isMainPath}
          >
            {!isMdDown && (
              <Icon
                // isFocus ? 'search-black' :
                name={
                  isMainPath && isSelectAdvertise ? 'search-white' : 'search'
                }
                size={isLargeScreen ? 24 : 16}
              />
            )}
            <SearchInput
              onChange={handleChange}
              placeholder="Collection, Item"
              value={inputQuery}
              onKeyDown={handleKeyDown}
              clickInputType={selectSearchInfoColor}
              {...inputFocusEventHandlers}
            />
            {inputQuery && (
              <ClearButton onClick={handleClear}>
                <Icon name="close-circle-gray" size={isMdDown ? 20 : 13.33} />
              </ClearButton>
            )}

            {isMdDown && <Icon name="search-black" size={24} />}
          </SearchInputBox>

          {((isLargeScreen && isFocus && query) ||
            (!isLargeScreen && query)) && (
            <SearchResult addRecentSearch={addRecentSearch} />
          )}

          {((isLargeScreen && isFocus && !query) ||
            (isLgScreen && !query) ||
            (isMdDown && !query)) && (
            <SearchDefaultResult
              recentSearch={recentSearch}
              handleSearch={handleSearch}
              handleDelete={handleDelete}
            />
          )}
        </SearchBoxBlock>
      )}
    </>
  );
};

export default SearchBox2;
