import styled from '@emotion/styled';
import { breakpoints, palette, spacing } from '@playdapp/ui';

import { useMedia } from 'react-use';
import Link from 'next/link';
import { getBreakpointQuery } from 'lib/util';

import Icon from '@/components/Icon';

type Props = {
  handleOpen: () => void;
};

const HeaderBlock = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 4.5rem;
  padding: ${spacing.m} 3rem;
  border-bottom: 1px solid ${palette.gray100};
  background-color: ${palette.white};
  z-index: 10;
  color: ${palette.dgray700};
  ${breakpoints.down('xl')} {
    height: 4rem;
    padding: ${spacing.s} 3rem;
  }
  ${breakpoints.down('md')} {
    height: 3.5rem;
    padding: ${spacing.s};
  }
`;

const LeftSection = styled.div`
  display: flex;
`;

const Brand = styled.a`
  display: flex;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 224px;
  height: 24px;
  .brand-market {
    width: 224px;
    height: 24px;
  }
  ${breakpoints.down('xl')} {
    width: 192px;
    height: 24px;
    .brand-market {
      width: 192px;
      height: 24px;
    }
  }
  ${breakpoints.down('md')} {
    width: 39px;
    width: 32px;
    .logo-md {
      width: 39px;
      width: 32px;
    }
  }
`;

const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  padding: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 1px solid ${palette.black};

  ${breakpoints.down('md')} {
    width: 32px;
    height: 32px;
  }

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }
`;

const OpenMenuHeader = ({ handleOpen }: Props) => {
  const isLargeUp = useMedia(getBreakpointQuery(breakpoints.up('lg')), true);
  const isMdDown = useMedia(getBreakpointQuery(breakpoints.down('md')), false);

  return (
    <HeaderBlock>
      <LeftSection>
        <Link href="/" passHref>
          <Brand>
            {!isMdDown && (
              <Icon
                name="brand-market"
                className="brand-market"
                width={isLargeUp ? 224 : 192}
                height={24}
                priority
              />
            )}
            {isMdDown && (
              <Icon
                name="logo-md"
                className="logo-md"
                width={39}
                height={32}
                priority
              />
            )}
          </Brand>
        </Link>
      </LeftSection>

      <CloseButton onClick={handleOpen}>
        <Icon name="close" size={isMdDown ? 16 : 20} />
      </CloseButton>
    </HeaderBlock>
  );
};

export default OpenMenuHeader;
