import { useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import {
  breakpoints,
  spacing,
  Typography,
  palette,
  fontStyle,
} from '@playdapp/ui';
import { useClickAway, useMedia } from 'react-use';
import { useInfiniteQuery } from 'react-query';
import { useInView } from 'react-intersection-observer';
import { Skeleton } from '@chakra-ui/react';
import times from 'lodash/times';

import { getAlarm } from 'api/alarm';
import { useAppSelector } from 'store/hooks';
import useOpenControl from 'hooks/useOpenControl';
import { getBreakpointQuery } from 'lib/util';

import Icon from './Icon';
import Notifications from './Notifications';
import NotificationSetting from './NotificationSetting';
import DeleteModal from './Modal/DeleteModal';

type StyleProps = {
  isSettingOpened: boolean;
};

type NoticeProps = {
  handleClick: () => void;
};

const ExploreMenuBlock = styled.div`
  width: 100%;
  box-shadow: none;
  background-color: ${palette.white};
`;

const NotificationBlock = styled.div<StyleProps>`
  z-index: 50;

  display: ${(props) => (props.isSettingOpened ? `none` : `block`)};

  ${breakpoints.down('md')} {
    background-color: ${palette.white};
    padding: ${spacing.l} ${spacing.xl};
  }
`;

const NotificationWrapper = styled.div`
  overflow-y: scroll;
  min-height: 31px;
  max-height: 430px;
  padding-right: 5px;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    width: 4px;
    background-color: ${palette.gray600};
    border-radius: 20px;
    margin-left: ${spacing.xs};
    margin-top: 51px;
  }

  ${breakpoints.down('md')} {
    min-height: none;
    max-height: calc(95vh - 6.6875rem);
  }
`;

const LoadingBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > div + div {
    margin-top: ${spacing.s};
  }
`;

const NotificationNumber = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing.xs};

  img {
    cursor: pointer;
  }
`;

const Setting = styled.div``;

const NotificationDelete = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const DeleteButton = styled.button`
  text-align: right;
  margin-bottom: ${spacing.xs};
  cursor: pointer;
`;

const EmptyNotificationBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${spacing.m} 0;

  ${breakpoints.down('md')} {
    padding: ${spacing.m};
  }
`;

const EmptyNotification = styled.div`
  ${fontStyle('p4')};
  color: ${palette.gray700};
`;

const NotificationMenu = ({ handleClick }: NoticeProps) => {
  const isMdDown = useMedia(getBreakpointQuery(breakpoints.down('md')), false);
  const { account } = useAppSelector(({ wallet }) => wallet);

  const [modalType, setModalType] = useState<string>('');
  const [modalIndex, setModalIndex] = useState(0);
  const [loadMoreRef, loadMoreRefInView] = useInView();
  const [currentCount, setCurrentCount] = useState<number | undefined>(
    undefined,
  );
  const [isOpenSettingMenu, setIsOpenSettingMenu] = useState(false);
  const [isDeleteModalOpen, handleDeleteModalOpen] = useOpenControl();

  const ref = useRef<HTMLDivElement | null>(null);

  const fetchNotificationList = async ({ pageParam = 1 }) => {
    if (!account) throw new Error('No account');

    if (pageParam === 1) {
      const { data } = await getAlarm({
        account,
        page: pageParam,
        startList: 1,
        endList: 10,
      });

      setCurrentCount(data?.totalCount);
      return { ...data, from: pageParam, nextPage: pageParam + 1 };
    }

    const { data } = await getAlarm({
      account,
      page: pageParam,
      startList: pageParam * 5 + 1,
      endList: pageParam * 5 + 5,
    });

    setCurrentCount(data?.totalCount);
    return { ...data, from: pageParam, nextPage: pageParam + 1 };
  };

  const {
    isLoading,
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery(['getAlarm', account], fetchNotificationList, {
    getNextPageParam: ({ totalCount, nextPage }) =>
      totalCount && Math.ceil(totalCount / 5) >= nextPage
        ? nextPage
        : undefined,
    enabled: !!account,
    cacheTime: 0,
    retry: 0,
  });

  const handleNextPage = useCallback(
    async () => hasNextPage && (await fetchNextPage()),
    [hasNextPage, fetchNextPage],
  );

  const handleOpenSettingMenu = useCallback(() => {
    const bodyElement = document.querySelector('body');

    if (isMdDown && !isOpenSettingMenu && bodyElement) {
      bodyElement.style.overflow = 'hidden';
    } else {
      bodyElement?.removeAttribute('style');
    }
    setIsOpenSettingMenu(!isOpenSettingMenu);
  }, [isOpenSettingMenu, isMdDown]);

  const onDataRefetch = useCallback(() => {
    refetch();
  }, [refetch]);

  const openModal = (type: string, index?: number) => {
    handleDeleteModalOpen(true);

    if (index) setModalIndex(index);
    setModalType(type);
  };

  useClickAway(ref, () => {
    setTimeout(() => {
      handleClick();
    }, 200);
  });

  useEffect(() => {
    if (!isFetching && !isFetchingNextPage && loadMoreRefInView)
      handleNextPage();
  }, [loadMoreRefInView, handleNextPage, isFetching, isFetchingNextPage]);

  useEffect(() => {
    refetch();
  }, [isDeleteModalOpen, refetch]);

  return (
    <>
      {isOpenSettingMenu && (
        <NotificationSetting handleOpen={handleOpenSettingMenu} />
      )}

      <ExploreMenuBlock ref={ref}>
        <NotificationBlock isSettingOpened={isOpenSettingMenu}>
          {isLoading && (
            <LoadingBlock>
              {times(1, (index) => (
                <Skeleton key={index} width="100%" height="82px" />
              ))}
            </LoadingBlock>
          )}

          {!isLoading && !account && (
            <>
              {!isMdDown && (
                <NotificationNumber>
                  <Typography type="p4" color="dgray300">
                    Notification
                  </Typography>
                </NotificationNumber>
              )}
              <EmptyNotificationBlock>
                <EmptyNotification>
                  This service requires login. Please login.
                </EmptyNotification>
              </EmptyNotificationBlock>
            </>
          )}

          {!isLoading &&
            !!account &&
            !!data &&
            !!currentCount &&
            currentCount > 0 && (
              <>
                {!isMdDown && (
                  <NotificationNumber>
                    <Typography type="p4" color="dgray300">
                      Notification ({currentCount})
                    </Typography>
                    <Setting
                      onClick={() => {
                        setIsOpenSettingMenu(true);
                      }}
                    >
                      <Icon name="setting" size={17} />
                    </Setting>
                  </NotificationNumber>
                )}
                <NotificationDelete>
                  <DeleteButton
                    onClick={() => {
                      openModal('all');
                    }}
                  >
                    <Typography type="p5" color="gray700">
                      Delete all
                    </Typography>
                  </DeleteButton>
                </NotificationDelete>
                <NotificationWrapper>
                  {data.pages.map((items) => {
                    if (items.list) {
                      const { list } = items;

                      return Object.values(list).map(
                        ({
                          timestamp,
                          content,
                          link,
                          index,
                          type,
                          tokenNetwork,
                          indicate,
                        }) => (
                          <Notifications
                            key={index}
                            type={type}
                            tokenNetwork={tokenNetwork}
                            timestamp={timestamp}
                            content={content}
                            link={link}
                            index={index}
                            showDot={indicate === 'on'}
                            openModal={openModal}
                            onDataRefetch={onDataRefetch}
                          />
                        ),
                      );
                    }

                    return (
                      <EmptyNotificationBlock>
                        <EmptyNotification>
                          An error occured. Please retry later.
                        </EmptyNotification>
                      </EmptyNotificationBlock>
                    );
                  })}

                  {hasNextPage && <div ref={loadMoreRef} />}
                </NotificationWrapper>
              </>
            )}

          {currentCount === 0 && (
            <>
              {!isMdDown && (
                <NotificationNumber>
                  <Typography type="p4" color="dgray300">
                    Notification
                  </Typography>
                  <Setting
                    onClick={() => {
                      setIsOpenSettingMenu(true);
                    }}
                  >
                    <Icon name="setting" size={17} />
                  </Setting>
                </NotificationNumber>
              )}
              <EmptyNotificationBlock>
                <EmptyNotification>
                  You do not have notifications.
                </EmptyNotification>
              </EmptyNotificationBlock>
            </>
          )}
        </NotificationBlock>
        <DeleteModal
          isOpen={isDeleteModalOpen}
          handleOpen={handleDeleteModalOpen}
          type={modalType}
          index={modalIndex}
        />
      </ExploreMenuBlock>
    </>
  );
};

export default NotificationMenu;
