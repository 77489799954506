import { useRouter } from 'next/router';
import { useEffect, useState, useMemo } from 'react';

const hideUrlList = ['faq', 'storefront', 'tos', 'policy'];

const useHide = () => {
  const router = useRouter();

  const [isHideLayout, setIsHideLayout] = useState(true);
  const isAppInQuery = useMemo(
    () => Object.keys(router.query).includes('app'),
    [router.query],
  );

  useEffect(() => {
    if (!router.isReady) return;
    const { asPath } = router;
    setIsHideLayout(
      !!hideUrlList.find((item) => asPath.includes(item)) && isAppInQuery,
    );
  }, [router, isAppInQuery]);

  return {
    isHideLayout,
    isAppInQuery,
  };
};
export default useHide;
