import { useState, useCallback } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { palette, spacing, Typography } from '@playdapp/ui';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { useDisconnect } from 'wagmi';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setIsModalOpen } from 'store/connectModal';
import { resetWalletInfo } from 'store/wallet';
import { resetUserInfo } from 'store/user';
import { setPageNetwork } from 'store/page';
import { removeLoginInfo } from 'lib/auth';
import useSolana from 'hooks/useSolana';
import useWeb3Info from 'hooks/useWeb3Info';

import Redirect from '@/components/Redirect';
import Icon from './Icon';
import Dot from './Dot';
import ExploreMenu from './ExploreMenu';
import HeaderMenu from './HeaderMenu';
import SignInModal from '@/components/Modal/SignInModal';

type DropdownMenuButtonProps = {
  name: string;
  isShowDot: boolean;
  onClick: () => void;
};

type Event = {
  id: string;
  title: string;
  link: string;
  isOnSale?: boolean;
  isShowDot?: boolean;
};

type Props = {
  eventList: Event[];
  onMenuClose: () => void;
};

const NavigationMenuBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const MenuItemBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${spacing.xl} 0;

  hr {
    width: 100%;
    height: 1px;
    margin-top: 1.25rem;
    border: none;
    background-color: ${palette.gray400};
  }
`;

const Dropdown = styled.div`
  padding: 0 ${spacing.xl};
  & + & {
    margin-top: ${spacing.l};
  }
`;

const DropdownButtonBlock = styled.button`
  display: flex;
  align-items: center;
  outline: 0;
  padding: 0;
  position: relative;

  .open {
    transform: rotate(180deg);
  }

  span {
    margin-right: ${spacing.xs};
  }
`;

const DropdownMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing.m} 2.25rem 0 2.25rem;

  & > a + a {
    margin-top: ${spacing.m};
  }
`;

const DropdownMenuLink = styled.a<{ isOnSale?: boolean; isActive?: boolean }>`
  position: relative;

  span {
    color: ${palette.dgray400};

    ${({ isActive }) =>
      isActive &&
      css`
        color: ${palette.marketplace} !important;
      `}
  }

  ${({ isOnSale }) =>
    isOnSale &&
    `
    display: flex;
    flex-direction: column;
    
  `}
`;

const MenuLink = styled.a<{ isActive?: boolean }>`
  margin-top: ${spacing.l};
  padding: 0 ${spacing.xl};

  span {
    color: ${palette.dgray700};

    ${({ isActive }) =>
      isActive &&
      css`
        color: ${palette.marketplace} !important;
      `}
  }
`;

const SigninButton = styled.div`
  margin-top: ${spacing.m};
  padding: 0 ${spacing.xl};
  color: ${palette.dgray700};

  span {
    margin-left: ${spacing.xs};
  }

  :hover {
    cursor: pointer;
  }
`;

const DropdownMenuButton = ({
  name,

  isShowDot,
  onClick,
}: DropdownMenuButtonProps) => (
  <DropdownButtonBlock onClick={onClick}>
    {isShowDot && <Dot top="0px" right="-16px" />}
    <Typography type="b4">{name}</Typography>
  </DropdownButtonBlock>
);

const NavigationMenu2 = ({ eventList, onMenuClose }: Props) => {
  const router = useRouter();
  const { deactivate } = useWeb3React<Web3Provider>();
  const { active } = useWeb3Info();
  const { disconnect: wagmiDisconnect } = useDisconnect();
  const { connected, disconnect } = useSolana();

  const [isExploreMenuOpen, setIsExploreMenuOpen] = useState(false);
  const [isEventMenuOpen, setIsEventMenuOpen] = useState(false);

  const { isModalOpen } = useAppSelector(({ connectModal }) => connectModal);

  const handleExploreMenuClick = () => {
    setIsExploreMenuOpen(!isExploreMenuOpen);
  };

  const handleEventMenuClick = () => {
    setIsEventMenuOpen(!isEventMenuOpen);
  };

  const dispatch = useAppDispatch();

  const handleConnectOpen = useCallback(
    (isModalOpen: boolean) => {
      dispatch(setIsModalOpen(isModalOpen));

      const body = document.querySelector('body');

      if (!body) return;

      if (isModalOpen) {
        body.style.overflowY = 'hidden';
        return;
      }

      body.style.overflowY = 'auto';
    },
    [dispatch],
  );

  const handleLogout = () => {
    removeLoginInfo();
    dispatch(resetWalletInfo());
    dispatch(resetUserInfo());
    dispatch(setPageNetwork(null));
    deactivate();
    disconnect();
    wagmiDisconnect();
    onMenuClose();
  };

  return (
    <NavigationMenuBlock>
      <MenuItemBlock>
        <Dropdown>
          <Redirect to="/nfts/all/all">
            <DropdownMenuButton
              name="Explore"
              isShowDot={false}
              onClick={handleExploreMenuClick}
            />
          </Redirect>

          {isExploreMenuOpen && (
            <HeaderMenu handleOpen={handleExploreMenuClick}>
              <ExploreMenu />
            </HeaderMenu>
          )}
        </Dropdown>

        {/* <Dropdown>
          <Redirect to="/drops">
            <DropdownMenuButton
              name="Drops"
              isShowDot={false}
              onClick={handleExploreMenuClick}
            />
          </Redirect>
        </Dropdown> */}

        <hr />
        <MenuLink
          href="https://playdapp.atlassian.net/servicedesk/customer/portals"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography type="p4">Customer Support</Typography>
        </MenuLink>
        <Link href="/faq/marketplace" passHref>
          <MenuLink isActive={router.asPath === '/faq/marketplace'}>
            <Typography type="p4">FAQ</Typography>
          </MenuLink>
        </Link>

        <hr />
        {!active && !connected && (
          <SigninButton onClick={() => handleConnectOpen(true)}>
            <Icon name="power" width={12} height={12.67} />
            <Typography type="p4">Sign in</Typography>
          </SigninButton>
        )}
        {(active || connected) && (
          <SigninButton onClick={handleLogout}>
            <Icon name="power" width={12} height={12.67} />
            <Typography type="p4">Sign out</Typography>
          </SigninButton>
        )}
      </MenuItemBlock>

      <SignInModal
        isOpen={isModalOpen}
        handleOpen={handleConnectOpen}
        onMenuClose={onMenuClose}
      />
    </NavigationMenuBlock>
  );
};

export default NavigationMenu2;
