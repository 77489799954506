import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual } from 'react-redux';
import Link from 'next/link';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { breakpoints, palette, spacing, Typography } from '@playdapp/ui';
import { useMedia } from 'react-use';
import times from 'lodash/times';
import { Skeleton } from '@chakra-ui/react';
import { useRouter } from 'next/router';

import type { NetworkName } from 'types/network';

import { useAppSelector, useAppDispatch } from 'store/hooks';
import { getBreakpointQuery, toPascalCase } from 'lib/util';
import { chainIdToMainNetworkName } from 'lib/network';
import { setRangeFilters } from 'store/filters';

import Button from './Button';
import Image from './Image';
import Icon from './Icon';

type NetworkButtonProps = {
  network: NetworkName;
  isActive: boolean;
  handleClick: (type: NetworkName) => () => void;
};

type StyledNetworkButtonProps = {
  isActive: boolean;
};

const ExploreMenuListBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 360px;
  overflow: hidden;

  ${breakpoints.down('lg')} {
    width: 100%;
  }

  hr {
    margin: ${spacing.l} 0 ${spacing.xl} 0;
  }
`;

const NetworkListBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;

  ${breakpoints.down('lg')} {
    justify-content: flex-start;
  }
`;

const StyledNetworkButton = styled(Button)<StyledNetworkButtonProps>`
  flex: 1;
  width: 33%;
  padding: ${spacing.s} ${spacing.m};
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid ${palette.gray400};
  background-color: transparent;
  color: ${palette.dgray600};

  &:hover {
    background-color: rgba(208, 209, 215, 0.15);
  }

  &:active {
    background-color: rgba(208, 209, 215, 0.1);
  }

  ${({ isActive }) =>
    isActive &&
    css`
      border-bottom: 1px solid ${palette.gray900};
    `};

  p {
    margin-left: ${spacing.xs};

    ${({ isActive }) =>
      !isActive &&
      css`
        color: ${palette.gray900};
      `};
  }
`;

const CategoryListBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${spacing.m};

  ${breakpoints.down('md')} {
    max-height: 60vh;
    overflow-y: scroll;
  }
`;

const CollectionLink = styled.a<{ isActive?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: ${spacing.xs} ${spacing.m};
  border: none;
  border-radius: 8px;

  & + & {
    margin-top: ${spacing.xs};
  }

  & > span,
  & > img {
    flex: none;
    border-radius: 12px;
  }

  p {
    position: relative;
    margin-left: ${spacing.s};
    padding-top: 1px;
    margin-bottom: -1px;

    ${({ isActive }) =>
      isActive &&
      css`
        color: ${palette.marketplace} !important;
      `}
  }

  &:hover {
    background-color: ${palette.gray200};
  }
`;

const SkeletonBlock = styled.div`
  width: 100%;

  & > div + div {
    margin-top: ${spacing.xs};
  }
`;

const NetworkButton = ({
  network,
  isActive,
  handleClick,
}: NetworkButtonProps) => (
  <StyledNetworkButton
    isOutlined
    isActive={isActive}
    onClick={handleClick(network)}
  >
    <Icon name={network} size={20} />
    <Typography type="b5" component="p">
      {toPascalCase(network)}
    </Typography>
  </StyledNetworkButton>
);

const CollectionSkeleton = React.memo(() => (
  <SkeletonBlock>
    {times(8, (index) => (
      <Skeleton key={index} width="100%" height="48px" borderRadius="8px" />
    ))}
  </SkeletonBlock>
));

const ExploreMenuList = () => {
  const router = useRouter();

  const { query, isCollectionsLoading, collectionList } = useAppSelector(
    ({ collections }) => collections,
    shallowEqual,
  );

  const { activeChainId } = useAppSelector(
    ({ wallet }) => wallet,
    shallowEqual,
  );

  const isLargeScreen = useMedia(
    getBreakpointQuery(breakpoints.up('lg')),
    true,
  );

  const [activeNetwork, setActiveNetwork] = useState<NetworkName>('polygon');
  const [activeCategory, setActiveCategory] = useState<{
    categoryId: number;
    network: NetworkName;
  } | null>(null);
  const [isMount, setIsMount] = useState(false);

  const dispatch = useAppDispatch();

  const isShowMenuItem = useMemo(
    () => (!isLargeScreen && !query) || isLargeScreen,
    [isLargeScreen, query],
  );

  const handleNetworkClick = (type: NetworkName) => () => {
    setActiveNetwork(type);
  };

  useEffect(() => {
    /**
     * 1순위: path에 네트워크로 판별
     * 2순위: 로그인 시 선택한 네트워크
     */
    if (router.query.slug && Array.isArray(router.query.slug)) {
      const pageNetwork = router.query.slug.find(
        (slug) =>
          slug.includes('polygon') ||
          slug.includes('ethereum') ||
          slug.includes('solana'),
      ) as NetworkName;

      setActiveNetwork(pageNetwork ?? 'polygon');
    } else if (activeChainId) {
      setActiveNetwork(chainIdToMainNetworkName[activeChainId]);
    }
    setIsMount(true);
  }, [router, activeChainId]);

  useEffect(() => {
    const pathname = router.asPath;
    if (/^\/collection/.test(pathname)) {
      const [, , network, categoryId] = pathname.split('/');

      if (
        (network === 'polygon' ||
          network === 'ethereum' ||
          network === 'solana') &&
        categoryId
      ) {
        setActiveCategory({
          categoryId: categoryId === 'all' ? 0 : parseInt(categoryId, 10),
          network,
        });
      }
    } else {
      setActiveCategory(null);
    }
  }, [router]);

  return (
    <ExploreMenuListBlock>
      {isMount && isShowMenuItem && (
        <>
          <NetworkListBlock>
            <NetworkButton
              network="polygon"
              isActive={activeNetwork === 'polygon'}
              handleClick={handleNetworkClick}
            />
            <NetworkButton
              network="ethereum"
              isActive={activeNetwork === 'ethereum'}
              handleClick={handleNetworkClick}
            />
            <NetworkButton
              network="solana"
              isActive={activeNetwork === 'solana'}
              handleClick={handleNetworkClick}
            />
          </NetworkListBlock>
          <CategoryListBlock>
            {isCollectionsLoading && <CollectionSkeleton />}
            {!isCollectionsLoading && (
              <>
                <Link href="/explore" passHref>
                  <CollectionLink
                    isActive={
                      activeCategory?.network === activeNetwork &&
                      activeCategory.categoryId === 0
                    }
                  >
                    <Image
                      prefix="s3"
                      src="/collections/all.png"
                      alt="category"
                      width={24}
                      height={24}
                    />
                    <Typography type="p3" color="dgray600" component="p">
                      All
                    </Typography>
                  </CollectionLink>
                </Link>
                {collectionList[activeNetwork].map((collection) => (
                  <Link
                    key={collection.categoryId}
                    href={`/collection/${activeNetwork}/${collection.categoryId}`}
                    passHref
                  >
                    <CollectionLink
                      isActive={
                        activeCategory?.network === activeNetwork &&
                        collection.categoryId === activeCategory.categoryId
                      }
                      onClick={() => {
                        dispatch(setRangeFilters([]));
                      }}
                    >
                      <Image
                        src={collection.thumbnailUrl}
                        alt="collection"
                        width={24}
                        height={24}
                      />
                      <Typography type="p3" color="dgray600" component="p">
                        {collection.title}
                      </Typography>
                    </CollectionLink>
                  </Link>
                ))}
              </>
            )}
          </CategoryListBlock>
        </>
      )}
    </ExploreMenuListBlock>
  );
};

export default ExploreMenuList;
