import { useState } from 'react';
import { useQuery } from 'react-query';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  breakpoints,
  spacing,
  Typography,
  palette,
  fontStyle,
} from '@playdapp/ui';

import { getAlarmSetting, patchAlarmSetting } from 'api/alarm';
import { useAppSelector } from 'store/hooks';

type Props = {
  handleOpen?: () => void;
};

type LabelProps = { checked: boolean };

type Status = {
  all: boolean;
  offer: boolean;
  outbid: boolean;
  event: boolean;
  nftDrops: boolean;
  collection: boolean;
};

const SettingWrapper = styled.div`
  position: relative;
  max-height: 644px;
  box-shadow: none;
  background-color: ${palette.white};
  z-index: 999;
  overflow: hidden;

  ${breakpoints.down('lg')} {
    width: 100%;
  }

  ${breakpoints.down('md')} {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
    padding: ${spacing.l} ${spacing.xl};
  }
`;

const Back = styled.button`
  margin-bottom: ${spacing.xl};
  ${fontStyle('p4')}
`;

const SettingList = styled.div``;

const SettingBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${spacing.xxs} 0;
  padding: ${spacing.s};
  border: 1px solid ${palette.gray400};
  border-radius: ${spacing.xs};
`;

const SettingLabel = styled.label<LabelProps>`
  display: inline-block;
  position: relative;
  width: 36px;
  height: 20px;
  background-color: ${(props) =>
    props.checked ? palette.blue500 : palette.gray700};
  border-radius: 360px;
  ${fontStyle('b5')}

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + .slider:before {
    right: 1px;
  }

  .slider {
    width: 18px;
    height: 18px;
    border-radius: 34px;
    background-color: ${palette.white};
    cursor: pointer;
    transition: all 0.3s ease-in;
  }

  .slider.round {
    position: absolute;
    top: 1px;
    left: 1px;
    right: 0;
    bottom: 0;
    transition: all 0.15s ease-out;

    ${(props) =>
      props.checked &&
      css`
        left: 17px;
      `};
  }

  .slider.round:before {
    position: absolute;
    content: '';
    top: 1px;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

const NotificationSetting = ({ handleOpen }: Props) => {
  const [status, setStatus] = useState({
    all: false,
    offer: false,

    outbid: false,
    event: false,
    nftDrops: false,
    collection: false,
  });

  const { account } = useAppSelector(({ wallet }) => wallet);

  useQuery(
    'getNotificationSetting',
    async () => {
      if (account) {
        const { data } = await getAlarmSetting({ account });

        if (data) {
          const dataStatus = {
            all:
              data &&
              !Object.values(data)
                .slice(1)
                .find((x) => x),
            offer: data?.offer,
            outbid: data?.outbid,
            event: data?.event,
            nftDrops: data?.nftDrops,
            collection: data?.collection,
          };

          setStatus(dataStatus);
          return dataStatus;
        }
      }
    },
    { enabled: !!account },
  );

  const fetchAlarmSetting = async (propsStatus: Status) => {
    if (account) {
      const res = await patchAlarmSetting({
        address: account,
        offer: propsStatus.offer,
        outbid: propsStatus.outbid,
        event: propsStatus.event,
        nftDrops: propsStatus.nftDrops,
        collection: propsStatus.collection,
      });

      if (res.status !== 200) {
        throw new Error('');
      }
    }
  };

  const handleAllToggle = () => {
    let newStatus = {
      ...status,
    };

    if (status.all) {
      newStatus = {
        all: false,
        offer: true,
        outbid: true,
        event: true,
        nftDrops: true,
        collection: true,
      };
    } else {
      newStatus = {
        all: true,
        offer: false,
        outbid: false,
        event: false,
        nftDrops: false,
        collection: false,
      };
    }

    fetchAlarmSetting(newStatus);
    setStatus(newStatus);
  };

  const handleToggle = (detailStatus: string) => {
    let newStatus = {
      ...status,
    };

    newStatus = {
      ...status,
      [detailStatus]: !status[detailStatus as keyof typeof status],
    };

    newStatus = {
      ...newStatus,
      all: !Object.values(newStatus)
        .slice(1)
        .find((x) => x),
    };

    fetchAlarmSetting(newStatus);
    setStatus(newStatus);
  };

  return (
    <SettingWrapper>
      <Back onClick={handleOpen}>&#60; Back</Back>
      <SettingList>
        <SettingBlock>
          <Typography type="b5">Block all messages</Typography>
          <SettingLabel htmlFor="allToggle" checked={status.all}>
            <input
              id="allToggle"
              type="checkbox"
              checked={status.all}
              onChange={handleAllToggle}
            />
            <span className="slider round" />
          </SettingLabel>
        </SettingBlock>
        <SettingBlock>
          <Typography type="b5">Offer</Typography>
          <SettingLabel htmlFor="offerToggle" checked={status.offer}>
            <input
              id="offerToggle"
              type="checkbox"
              checked={status.offer}
              onChange={() => {
                handleToggle('offer');
              }}
            />
            <span className="slider round" />
          </SettingLabel>
        </SettingBlock>

        <SettingBlock>
          <Typography type="b5">Outbid</Typography>
          <SettingLabel htmlFor="outbidToggle" checked={status.outbid}>
            <input
              id="outbidToggle"
              type="checkbox"
              checked={status.outbid}
              onChange={() => {
                handleToggle('outbid');
              }}
            />
            <span className="slider round" />
          </SettingLabel>
        </SettingBlock>
        <SettingBlock>
          <Typography type="b5">Event</Typography>
          <SettingLabel htmlFor="eventToggle" checked={status.event}>
            <input
              id="eventToggle"
              type="checkbox"
              checked={status.event}
              onChange={() => {
                handleToggle('event');
              }}
            />
            <span className="slider round" />
          </SettingLabel>
        </SettingBlock>
        <SettingBlock>
          <Typography type="b5">NFT drop</Typography>
          <SettingLabel htmlFor="dropsToggle" checked={status.nftDrops}>
            <input
              id="dropsToggle"
              type="checkbox"
              checked={status.nftDrops}
              onChange={() => {
                handleToggle('nftDrops');
              }}
            />
            <span className="slider round" />
          </SettingLabel>
        </SettingBlock>
        <SettingBlock>
          <Typography type="b5">Collection</Typography>
          <SettingLabel htmlFor="collectionToggle" checked={status.collection}>
            <input
              id="collectionToggle"
              type="checkbox"
              checked={status.collection}
              onChange={() => {
                handleToggle('collection');
              }}
            />
            <span className="slider round" />
          </SettingLabel>
        </SettingBlock>
      </SettingList>
    </SettingWrapper>
  );
};

export default NotificationSetting;
