/** 이미지 로딩에 실패 했을 때 placeholder 이미지를 렌더링 합니다 */
const renderPlaceholderImage = (
  e: React.SyntheticEvent<HTMLImageElement, Event>,
) => {
  e.currentTarget.src =
    'https://images.playdapp.com/marketplace-dev/collections/placeholder2v.png';
};

/** 이미지 로딩에 실패 했을 때 src 파라미터의 이미지를 렌더링 합니다 */
export const renderFungibleImage =
  (src: string) => (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = src;
  };

export default renderPlaceholderImage;
