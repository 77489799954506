import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  breakpoints,
  fontStyle,
  palette,
  Progress,
  spacing,
  Typography,
} from '@playdapp/ui';
// import { useWeb3React } from '@web3-react/core';
// import { Web3Provider } from '@ethersproject/providers';
import { useClickAway, useMedia, useWindowScroll } from 'react-use';
import classNames from 'classnames';
import { useQuery } from 'react-query';
import { lowerCase } from 'lodash';
import { Slide } from '@chakra-ui/react';

import useHide from 'hooks/useHide';
import useSolana from 'hooks/useSolana';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setPrevPage } from 'store/page';
import { getDrops } from 'store/dropsList';
import { setIsModalOpen } from 'store/connectModal';
import { getBreakpointQuery } from 'lib/util';
import { chainIdToMainNetworkName } from 'lib/network';
import { getAlarmRedDot } from 'api/alarm';

import Dot from '@/components/Dot';
import Icon from '@/components/Icon';
import Image from '@/components/Image';
import Button from '@/components/Button';
import Redirect from '@/components/Redirect';
import UserInfo2 from '@/components/UserInfo2';
import SearchBox2 from '@/components/SearchBox2';
import SearchMenu2 from '@/components/SearchMenu2';
import HeaderMenu2 from '@/components/HeaderMenu2';
import NavigationMenu2 from '@/components/NavigationMenu2';
import NotificationMenu2 from '@/components/NotificationMenu2';
import SignInModal from '@/components/Modal/SignInModal';

type DropdownProps = {
  name: string;
  type: string;
  selectIsFucus: boolean;
  showDot?: boolean;
  scrollDown: boolean;
  isSelectAdvertise: boolean;
};

type DropdownButtonProps = {
  name: string;
  isSelectAdvertise: boolean;
  selectIsFucus: boolean;
  isMainPath: boolean;
  scrollDown: boolean;
  onClick: () => void;
};

type MenuProps = {
  children: React.ReactNode;
  type: 'activity' | 'navigation' | 'user';
};

type MenuButtonProps = {
  icon:
    | 'bell'
    | 'menu-padding-white'
    | 'menu-padding'
    | 'menu-white'
    | 'menu'
    | 'profile'
    | 'search-black'
    | 'search-padding-white'
    | 'search-padding'
    | 'search-white';
  onClick: () => void;
};

type NotificationProps = {
  isSelectAdvertise: boolean;
  isMainPath: boolean;
  isShowDot: boolean;
  scrollDown: boolean;
  selectIsFucus: boolean;
  onClick: () => void;
};

type NotificationMdProps = {
  isShowDot: boolean;
  isMainPath: boolean;
  isSelectAdvertise: boolean;
  scrollDown: boolean;
  selectIsFucus: boolean;
  onClick: () => void;
};

type NotificationButtonProps = {
  icon:
    | 'bell-padding-white'
    | 'bell-padding'
    | 'bell-ringing-white'
    | 'bell-ringing'
    | 'bell-white'
    | 'bell';
  onClick: () => void;
};

const HeaderBlock = styled.header<{
  disableShadow?: boolean;
  isHide: boolean;
  selectIsFucus: boolean;
}>`
  position: fixed;
  top: 0;
  display: ${({ isHide }) => (isHide ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4.5rem;
  margin: 0 auto;
  padding: ${spacing.m} ${spacing.xl};
  z-index: 10;
  color: ${palette.dgray700};
  transition-property: background-color, box-shadow, border;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  background-color: ${({ selectIsFucus }) =>
    selectIsFucus ? 'rgba(250, 250, 251, 0.9)' : '#fffff'};

  ${({ disableShadow }) =>
    !disableShadow &&
    css`
      box-shadow: 0 4px 8px rgba(21, 22, 25, 0.05);
    `}

  &:not(.open-menu).body-scroll {
    background-color: rgba(250, 250, 251, 0.9);
    backdrop-filter: blur(8px);
  }

  ${breakpoints.down('xxl')} {
    height: 4.5rem;
  }

  ${breakpoints.down('xl')} {
    height: 4rem;
    padding: 18px 32px;
  }

  ${breakpoints.down('md')} {
    padding: 18px ${spacing.m};
  }

  ${breakpoints.down('sm')} {
    height: 3.5rem;
  }
`;

const HeaderContent = styled.div`
  display: flex;

  width: 100%;
  max-width: 1560px;
`;

const LeftSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: none;

  ${breakpoints.down('xxl')} {
    flex: initial;

    & > div {
      max-width: 368px;
    }
  }
`;

const Brand = styled.a`
  display: flex;
  flex: none;
  align-items: center;
  width: 224px;
  height: 28px;

  .brand-market {
    width: 224px;
    height: 28px;
  }

  ${breakpoints.down('xl')} {
    width: 192px;
    height: 24px;

    .brand-market {
      width: 192px;
      height: 24px;
    }
  }

  ${breakpoints.down('md')} {
    width: 192px;
    height: 24px;
  }

  ${breakpoints.down('sm')} {
    width: 160px;
    height: 20px;
  }
`;

const SearchBoxBlock = styled.div`
  display: flex;
  width: 100%;

  ${breakpoints.down('lg')} {
    display: none;
  }
`;

const NavBar = styled.nav`
  display: flex;
  justify-content: space-between;
  flex: 1;

  ${breakpoints.down('lg')} {
    display: flex;
    width: 100%;
  }
`;

const LeftNav = styled.div`
  display: flex;
`;

const Dropdowns = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 0 40px;
  gap: 40px;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${breakpoints.down('xl')} {
    margin: 0 32px;
  }

  ${breakpoints.down('lg')} {
    margin: 0 24px;
  }
`;

const NavItemButton = styled.div<{
  isMainPath: boolean;
  isSelectAdvertise: boolean;
  scrollDown: boolean;
}>`
  display: flex;
  justify-content: center;
  border-radius: 12px;
  background-color: transparent;
  color: ${palette.dgray200};

  &:hover {
    span {
      transition: 0.3s;
      color: ${({ isMainPath, isSelectAdvertise, scrollDown }) =>
        isMainPath && isSelectAdvertise && !scrollDown
          ? palette.gray600
          : palette.black};
    }
  }
`;

const NotificationBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
`;

const MenuIconButtonStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  padding: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;

  ${breakpoints.down('lg')} {
    width: 32px;
    height: 32px;
  }

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }
`;

const MenuIconButton = styled.button`
  ${MenuIconButtonStyle};
`;

const ProfileIconButton = styled.button`
  ${MenuIconButtonStyle};
  position: relative;
  z-index: 1;
  background-color: ${palette.marketplace};
  margin: 0 4px;

  & > img {
    border: 1px solid transparent;

    background-image: linear-gradient(#fff, #fff),
      linear-gradient(to right, #74d1fc 5%, #4ec1f3 50%, #2bcab0 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-radius: 8px;
  }

  p {
    position: absolute;
    z-index: 2;
    padding-top: 1px;
    margin-bottom: -1px;
  }
`;

const DropdownBlock = styled.div<{
  isAsPath: boolean;
  type: string;
}>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: ${({ theme }) => theme.rem(16)};
  height: 100%;

  ::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 0;
    border-bottom: ${({ isAsPath, type }) =>
      isAsPath && type !== 'user' && `1px solid`};
    animation: border_anim 0.4s linear forwards;
    left: 0;
  }

  @keyframes border_anim {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }

  ${palette.dgray700};

  ${breakpoints.down('md')} {
    margin: 0;
  }
`;

const MenuBlock = styled.div`
  display: flex;
  align-items: center;
  flex: none;
  gap: 8px;
`;

const ProfileImage = styled.div<{ isStake: boolean }>`
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;

  & > img {
    border-radius: 8px;
    object-fit: cover;
  }

  span {
    border-radius: 8px;
  }
`;

const UserMenuList = styled.div`
  display: flex;
`;

const SigninButton = styled(Button)<{
  selectIsFucus: boolean;
  nonFocusSignin: string;
  nonFocusTitle: string;
  nonFocusBackground: string;
  selectColor: string;
}>`
  flex: none;
  padding: ${spacing.xs} ${spacing.l};
  margin-left: ${spacing.xs};
  ${fontStyle('p3')};
  border: 1px solid
    ${({ selectIsFucus, nonFocusTitle }) =>
      selectIsFucus ? palette.gray900 : nonFocusTitle};
  border-radius: 8px;
  color: ${({ selectIsFucus, nonFocusSignin }) =>
    selectIsFucus ? palette.dgray600 : nonFocusSignin};
  background-color: transparent;

  &:hover {
    color: ${({ selectColor, selectIsFucus }) =>
      selectIsFucus ? selectColor : palette.dgray600};
    background-color: ${({ selectIsFucus, nonFocusBackground }) =>
      selectIsFucus ? 'transparent' : nonFocusBackground};
  }
`;

const eventList = [
  {
    id: 'ariel',
    title: 'PlayDapp Gallery : Ariel Han',
    link: '/events/ariel',
  },
  {
    id: 'playdapp-land',
    title: 'PlayDapp Land',
    link: '/events/playdapp-land',
  },
  {
    id: 'ariel',
    title: 'PlayDapp Gallery : Ariel Han',
    link: '/events/ariel',
  },
];

const DropdownButton = ({
  isMainPath,
  isSelectAdvertise,
  scrollDown,
  name,
  selectIsFucus,
  onClick,
}: DropdownButtonProps) => {
  const isExtraLargeDown = useMedia(
    getBreakpointQuery(breakpoints.down('xl')),
    false,
  );

  const changeNonFocus =
    isMainPath && isSelectAdvertise && !scrollDown ? 'white' : 'dgray200';

  return (
    <NavItemButton
      onClick={onClick}
      isMainPath={isMainPath}
      scrollDown={scrollDown}
      isSelectAdvertise={isSelectAdvertise}
    >
      <Typography
        type={isExtraLargeDown ? 'b4' : 'b3'}
        color={selectIsFucus ? 'dgray200' : changeNonFocus}
      >
        {name}
      </Typography>
    </NavItemButton>
  );
};

function Dropdown({
  name,
  type,
  showDot,
  scrollDown,
  selectIsFucus,
  isSelectAdvertise,
}: DropdownProps) {
  const router = useRouter();

  const [isOpen, setIsOpen] = useState(false);
  const [isOverButton, setIsOverButton] = useState(false);
  const [isOverList, setIsOverList] = useState(false);
  const [hasClicked, setHasClicked] = useState(false);
  const button = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleRouteChange = () => {
      if (isOpen) {
        setIsOpen(false);
        setIsOverButton(false);
        setIsOverList(false);
        setHasClicked(false);
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router, isOpen]);

  useEffect(() => {
    if (isOpen && !isOverButton && !isOverList && !hasClicked) {
      setIsOpen(() => false);
    } else if (!isOpen && (isOverButton || isOverList) && !hasClicked) {
      setIsOpen(() => true);
    }
  }, [isOpen, isOverButton, isOverList, hasClicked]);

  const explorePath =
    (router.asPath === '/nfts/all/all' ||
      router.asPath === '/nfts/all/all/collections') &&
    'explore';

  return (
    <DropdownBlock
      ref={button}
      type={type}
      isAsPath={
        explorePath
          ? explorePath.includes(lowerCase(name))
          : router.pathname.includes(lowerCase(name))
      }
    >
      {showDot && <Dot top="4px" right="12px" />}
      <DropdownButton
        name={name}
        scrollDown={scrollDown}
        isSelectAdvertise={isSelectAdvertise}
        selectIsFucus={selectIsFucus}
        isMainPath={router.pathname === '/'}
        onClick={() => {
          setHasClicked(true);
          setIsOpen(!isOpen);
        }}
      />
    </DropdownBlock>
  );
}

const NotificationModal = styled.div`
  position: absolute;
  top: 2.75rem;
  right: 0;
  min-width: 360px;
  max-height: 525px;
  padding: ${spacing.m};
  background-color: ${palette.white};
  border-radius: ${({ theme }) => theme.rem(16)};
  box-shadow: 0 1.75rem 2rem 0 rgba(76, 79, 110, 0.2);

  ${breakpoints.down('sm')} {
    display: none;
  }
`;

const NotificationButton = ({ icon, onClick }: NotificationButtonProps) => (
  <MenuIconButton className="button-menu-icon" onClick={onClick}>
    <Icon name={icon} size={32} />
  </MenuIconButton>
);

const Notification = ({
  isSelectAdvertise,
  isMainPath,
  isShowDot,
  scrollDown,
  selectIsFucus,
  onClick,
}: NotificationProps) => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);

  const onNonFocusBell =
    isSelectAdvertise && isMainPath && !scrollDown
      ? 'bell-padding-white'
      : 'bell-padding';

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleRouteChange = () => {
      if (isOpen) {
        setIsOpen(false);
      }
    };
    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router, isOpen]);

  return (
    <NotificationBlock onClick={onClick}>
      {isShowDot && <Dot top="0px" left="35px" />}
      <NotificationButton
        icon={selectIsFucus ? 'bell-padding' : onNonFocusBell}
        onClick={handleClick}
      />
      {isOpen && (
        <NotificationModal>
          <NotificationMenu2 handleClick={handleClick} />
        </NotificationModal>
      )}
    </NotificationBlock>
  );
};

const NotificationMd = ({
  isShowDot,
  isSelectAdvertise,
  isMainPath,
  scrollDown,
  selectIsFucus,
  onClick,
}: NotificationMdProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const onNonFocusBell =
    isSelectAdvertise && isMainPath && !scrollDown
      ? 'bell-padding-white'
      : 'bell-padding';

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <NotificationBlock onClick={onClick}>
      {isShowDot && <Dot top="0px" right="5px" />}
      <NotificationButton
        icon={selectIsFucus ? 'bell-padding' : onNonFocusBell}
        onClick={handleClick}
      />
    </NotificationBlock>
  );
};

const MenuButton = ({ icon, onClick }: MenuButtonProps) => (
  <MenuIconButton className="button-menu-icon" onClick={onClick}>
    <Icon name={icon} size={32} />
  </MenuIconButton>
);

function ProfileMenuButton({ onClick }: Omit<MenuButtonProps, 'icon'>) {
  const { activeChainId } = useAppSelector(({ wallet }) => wallet);
  const { profileTokenId, membersMikeyGrade } = useAppSelector(
    ({ user }) => user,
  );

  const networkName = useMemo(
    () => (activeChainId ? chainIdToMainNetworkName[activeChainId] : 'polygon'),
    [activeChainId],
  );

  return (
    <ProfileIconButton className="button-profile-icon" onClick={onClick}>
      {networkName === 'polygon' ? (
        <ProfileImage isStake>
          {profileTokenId && membersMikeyGrade ? (
            <Image
              src={`${process.env.NEXT_PUBLIC_MEMBERS_IMAGE_URL}/${membersMikeyGrade}/gif/${profileTokenId}.gif`}
              layout="fill"
              objectFit="cover"
              width={32}
              height={32}
            />
          ) : (
            <Image
              prefix="s3"
              src="/mypage/empty-stake.png"
              layout="fill"
              objectFit="cover"
              alt="info-img"
            />
          )}
        </ProfileImage>
      ) : (
        <ProfileImage isStake>
          <Image
            prefix="s3"
            src="/mypage/empty-stake.png"
            layout="fill"
            objectFit="cover"
            alt="info-img"
          />
        </ProfileImage>
      )}
    </ProfileIconButton>
  );
}

const menuIcons = {
  activity: 'bell' as const,
  user: 'profile' as const,
  navigation: 'menu' as const,
};

function Menu({ children, type }: MenuProps) {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  useClickAway(ref, () => {
    setIsOpen(false);
  });

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleRouteChange = () => {
      if (isOpen) {
        setIsOpen(false);
      }
    };
    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router, isOpen]);

  return (
    <DropdownBlock ref={ref} isAsPath type="user">
      {type !== 'user' && (
        <MenuButton icon={menuIcons[type]} onClick={handleClick} />
      )}
      {type === 'user' && <ProfileMenuButton onClick={handleClick} />}
      {isOpen && children}
    </DropdownBlock>
  );
}

const Header = () => {
  const router = useRouter();
  // const { active, chainId } = useWeb3React<Web3Provider>();
  const isMdSizeUp = useMedia(getBreakpointQuery(breakpoints.up('md')), true);
  const isLargeDown = useMedia(
    getBreakpointQuery(breakpoints.down('lg')),
    true,
  );
  const isMdDown = useMedia(getBreakpointQuery(breakpoints.down('md')), false);

  const {
    // connected,
    connecting,
  } = useSolana();

  const {
    account,
    chainId,
    isLoading: isWalletLoading,
    library,
  } = useAppSelector(({ wallet }) => wallet);

  const { isModalOpen } = useAppSelector(({ connectModal }) => connectModal);

  const { selectIsFucus } = useAppSelector(({ header }) => header);

  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenExplore, setIsOpenExplore] = useState(false);
  const [isOpenHeaderMenu, setIsOpenHeaderMenu] = useState(false);
  const [isOpenSearchMenu, setIsOpenSearchMenu] = useState(false);
  const [isOpenNotificationMenu, setIsOpenNotificationMenu] = useState(false);
  const [isOpenUserInfoMenu, setIsOpenUserInfoMenu] = useState(false);

  const [isAlarmDot, setIsAlarmDot] = useState(false);

  const handleConnectOpen = useCallback(
    (isModalOpen: boolean) => {
      dispatch(setIsModalOpen(isModalOpen));

      const body = document.querySelector('body');

      if (!body) return;

      if (isModalOpen) {
        body.style.overflowY = 'hidden';
        return;
      }

      body.style.overflowY = 'auto';
    },
    [dispatch, isModalOpen],
  );

  const { isHideLayout } = useHide();

  const { isSelectAdvertise } = useAppSelector(({ advertise }) => advertise);

  const { y } = useWindowScroll();
  const scrollDown = y > 1;

  const isConnecting = useMemo(
    () => connecting || isWalletLoading,
    [connecting, isWalletLoading],
  );

  // const isActive = useMemo(() => active || connected, [active, connected]);
  const isMainPath = router.asPath === '/';

  const isOpenMenu = useMemo(
    () => isOpenHeaderMenu || isOpenExplore || isOpenUserInfoMenu,
    [isOpenHeaderMenu, isOpenExplore, isOpenUserInfoMenu],
  );

  const mainAdverNonScroll = isMainPath && isSelectAdvertise && !scrollDown;

  const nonFocusLogo = mainAdverNonScroll
    ? 'brand-market-white'
    : 'brand-market';

  const nonFocusTitle = mainAdverNonScroll ? 'white' : '#999CA8';
  const nonFocusSignin = mainAdverNonScroll ? 'white' : 'gray900';
  const nonFocusSigninHover = mainAdverNonScroll ? 'black' : 'white';
  const nonFocusBackground = mainAdverNonScroll ? 'white' : 'gray900';
  const selectColor = selectIsFucus ? palette.gray900 : nonFocusSigninHover;

  const isLocatedMain = useMemo(
    () => router.pathname === '/',
    [router.pathname],
  );

  const { data: alarmDotData } = useQuery(
    'getAlarmDot',
    async () => {
      if (account) {
        const res = await getAlarmRedDot({ account });

        return res.data;
      }
      return false;
    },
    {
      enabled: !!account,
    },
  );

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleOpenExplore = useCallback(() => {
    const bodyElement = document.querySelector('body');
    if (!isOpenExplore && bodyElement) {
      bodyElement.style.overflow = 'hidden';
    } else {
      bodyElement?.removeAttribute('style');
    }
    setIsOpenExplore(!isOpenExplore);
  }, [isOpenExplore]);

  const handleOpenHeaderMenu = useCallback(() => {
    const bodyElement = document.querySelector('body');
    if (!isOpenHeaderMenu && bodyElement) {
      bodyElement.style.overflow = 'hidden';
    } else {
      bodyElement?.removeAttribute('style');
    }
    setIsOpenHeaderMenu(!isOpenHeaderMenu);
  }, [isOpenHeaderMenu]);

  const handleOpenSearchMenu = useCallback(() => {
    const bodyElement = document.querySelector('body');

    if (isMdDown && !isOpenSearchMenu && bodyElement) {
      bodyElement.style.overflow = 'hidden';
    } else {
      bodyElement?.removeAttribute('style');
    }

    setIsOpenSearchMenu(!isOpenSearchMenu);
  }, [isMdDown, isOpenSearchMenu]);

  const handleOpenNotificationMenu = useCallback(() => {
    const bodyElement = document.querySelector('body');

    if (isMdDown && !isOpenNotificationMenu && bodyElement) {
      bodyElement.style.overflow = 'hidden';
    } else {
      bodyElement?.removeAttribute('style');
    }

    setIsOpenNotificationMenu(!isOpenNotificationMenu);
  }, [isMdDown, isOpenNotificationMenu]);

  const handleOpenUserInfoMenu = useCallback(() => {
    const bodyElement = document.querySelector('body');
    if (!isOpenUserInfoMenu && bodyElement) {
      bodyElement.style.overflow = 'hidden';
    } else {
      bodyElement?.removeAttribute('style');
    }
    setIsOpenUserInfoMenu(!isOpenUserInfoMenu);
  }, [isOpenUserInfoMenu]);

  useEffect(() => {
    const handleRouteChange = () => {
      dispatch(setPrevPage(router.asPath));
      if (isOpenExplore) {
        handleOpenExplore();
      }
      if (isOpenHeaderMenu) {
        handleOpenHeaderMenu();
      }
      if (isOpenSearchMenu) {
        handleOpenSearchMenu();
      }
      if (isOpenNotificationMenu) {
        handleOpenNotificationMenu();
      }
      if (isOpenUserInfoMenu) {
        handleOpenUserInfoMenu();
      }
    };
    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [
    isOpenExplore,
    isOpenHeaderMenu,
    isOpenSearchMenu,
    isOpenNotificationMenu,
    isOpenUserInfoMenu,
    router,
    handleOpenExplore,
    handleOpenHeaderMenu,
    handleOpenSearchMenu,
    handleOpenNotificationMenu,
    handleOpenUserInfoMenu,
    dispatch,
  ]);

  useEffect(() => {
    setIsAlarmDot(alarmDotData);
  }, [alarmDotData]);

  useEffect(() => {
    if (isMdSizeUp) {
      const bodyElement = document.querySelector('body');
      setIsOpenUserInfoMenu(false);
      setIsOpenExplore(false);
      setIsOpenHeaderMenu(false);
      bodyElement?.removeAttribute('style');
    }
  }, [isMdSizeUp]);

  useEffect(() => {
    dispatch(
      getDrops({
        library: chainId === 137 || chainId === 80002 ? library : null,
      }),
    );
  }, [dispatch, library, chainId]);

  return (
    <HeaderBlock
      className={classNames({ 'body-scroll': y > 1, 'open-menu': isOpenMenu })}
      disableShadow={isLocatedMain && !y}
      isHide={isHideLayout}
      selectIsFucus={selectIsFucus}
    >
      <HeaderContent>
        <LeftSection>
          <Link href="/" passHref>
            <Brand>
              {!isMdDown && (
                <Icon
                  name={selectIsFucus ? 'brand-market' : nonFocusLogo}
                  className="brand-market"
                  layout="intrinsic"
                  width={isMdSizeUp ? 224 : 192}
                  height={isMdSizeUp ? 28 : 24}
                  priority
                  quality={100}
                />
              )}
              {isMdDown && (
                <Icon
                  name={selectIsFucus ? 'brand-market' : nonFocusLogo}
                  className="brand-market"
                  width={192}
                  height={24}
                  priority
                  quality={100}
                />
              )}
            </Brand>
          </Link>
        </LeftSection>

        <NavBar>
          <LeftNav>
            {!isMdDown && (
              <Dropdowns>
                <Redirect to="/nfts/all/all">
                  <Dropdown
                    name="Explore"
                    type="explore"
                    scrollDown={y > 1}
                    isSelectAdvertise={isSelectAdvertise}
                    selectIsFucus={selectIsFucus}
                  />
                </Redirect>

                {/* <Redirect to="/drops">
                  <Dropdown
                    name="Drops"
                    type="drops"
                    scrollDown={y > 1}
                    isSelectAdvertise={isSelectAdvertise}
                    selectIsFucus={selectIsFucus}
                  />
                </Redirect> */}
              </Dropdowns>
            )}

            <SearchBoxBlock>
              <SearchBox2 />
            </SearchBoxBlock>
          </LeftNav>

          <MenuBlock>
            {isLargeDown && !isMdDown && (
              <>
                <MenuButton
                  icon={
                    isSelectAdvertise && isMainPath
                      ? 'search-padding-white'
                      : 'search-padding'
                  }
                  onClick={handleOpenSearchMenu}
                />

                {isOpenSearchMenu && <SearchBox2 />}
              </>
            )}

            {isMdDown && (
              <>
                <MenuButton
                  icon={
                    isSelectAdvertise && !scrollDown
                      ? 'search-padding-white'
                      : 'search-padding'
                  }
                  onClick={handleOpenSearchMenu}
                />

                <Slide
                  direction="right"
                  in={isOpenSearchMenu}
                  style={{ zIndex: 10 }}
                >
                  <SearchMenu2 handleOpen={handleOpenSearchMenu} />
                </Slide>

                <NotificationMd
                  isShowDot={isAlarmDot}
                  scrollDown={scrollDown}
                  isMainPath={isMainPath}
                  isSelectAdvertise={isSelectAdvertise}
                  selectIsFucus={selectIsFucus}
                  onClick={handleOpenNotificationMenu}
                />

                <Slide
                  direction="right"
                  in={isOpenNotificationMenu}
                  style={{ zIndex: 10 }}
                >
                  <HeaderMenu2
                    title="Notification"
                    handleOpen={handleOpenNotificationMenu}
                    isOpen={isOpenNotificationMenu}
                  >
                    <NotificationMenu2 handleClick={handleClick} />
                  </HeaderMenu2>
                </Slide>
              </>
            )}

            {!isMdDown && (
              <Notification
                isSelectAdvertise={isSelectAdvertise}
                isMainPath={router.asPath === '/'}
                scrollDown={scrollDown}
                isShowDot={isAlarmDot}
                selectIsFucus={selectIsFucus}
                onClick={handleOpenNotificationMenu}
              />
            )}

            {!!account && (
              <UserMenuList>
                {!isMdDown && (
                  <Menu type="user">
                    <UserInfo2 />
                  </Menu>
                )}
                {isMdDown && (
                  <>
                    <ProfileMenuButton
                      onClick={() => handleOpenUserInfoMenu()}
                    />
                    <Slide
                      direction="right"
                      in={isOpenUserInfoMenu}
                      style={{ zIndex: 10 }}
                    >
                      <HeaderMenu2
                        title="My Account"
                        handleOpen={handleOpenUserInfoMenu}
                        isOpen={isOpenUserInfoMenu}
                      >
                        <UserInfo2 />
                      </HeaderMenu2>
                    </Slide>
                  </>
                )}
              </UserMenuList>
            )}
            {isMdSizeUp && !account && (
              <SigninButton
                isOutlined
                color={selectIsFucus ? 'gray900' : 'white'}
                selectIsFucus={selectIsFucus}
                nonFocusSignin={nonFocusSignin}
                nonFocusBackground={nonFocusBackground}
                selectColor={selectColor}
                nonFocusTitle={nonFocusTitle}
                isDisabled={isWalletLoading}
                onClick={() => handleConnectOpen(true)}
              >
                {isConnecting && (
                  <Progress color={palette.primary800} size="small" />
                )}
                {isConnecting ? <span>&nbsp;Connecting...</span> : 'Sign in'}
              </SigninButton>
            )}

            {isMdDown && (
              <>
                <MenuButton
                  icon={
                    isSelectAdvertise && !scrollDown
                      ? 'menu-padding-white'
                      : 'menu-padding'
                  }
                  onClick={handleOpenHeaderMenu}
                />

                <HeaderMenu2
                  title="Menu"
                  handleOpen={handleOpenHeaderMenu}
                  isOpen={isOpenHeaderMenu}
                >
                  <NavigationMenu2
                    eventList={eventList}
                    onMenuClose={handleOpenHeaderMenu}
                  />
                </HeaderMenu2>
              </>
            )}
          </MenuBlock>
        </NavBar>
      </HeaderContent>

      <SignInModal isOpen={isModalOpen} handleOpen={handleConnectOpen} />
    </HeaderBlock>
  );
};

Dropdown.defaultProps = {
  showDot: false,
};

export default Header;
