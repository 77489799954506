import dynamic from 'next/dynamic';
import styled from '@emotion/styled';

import { breakpoints, palette, spacing, Typography } from '@playdapp/ui';

import { useAppDispatch } from 'store/hooks';
import { setIsModalOpen } from 'store/connectModal';
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect';

import Icon from '@/components/Icon';

import { DrawerProps } from '.';

const Drawer = dynamic(() => import('@/components/Drawer'), {
  ssr: false,
});

type Props = DrawerProps;

const ModalContents = styled.div`
  width: 100vw;
  height: 100svh;
  padding: ${spacing.xl};
  background-color: ${palette.white};
`;

const SignInTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing.m};

  ${breakpoints.down('md')} {
    margin-bottom: ${spacing.s};
  }
`;

const WalletDrawer = ({ children, handleOpen, isOpen, ...props }: Props) => {
  const dispatch = useAppDispatch();

  const handleResize = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useIsomorphicLayoutEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Drawer
      isOpen={isOpen}
      handleOpen={handleOpen}
      zIndex={11}
      hideBodyScroll
      {...props}
    >
      <ModalContents>
        <SignInTitle>
          <Typography type="h5">Sign in</Typography>
          <Icon
            name="close"
            size={32}
            onClick={() => dispatch(setIsModalOpen(false))}
          />
        </SignInTitle>
        <Typography type="p5">
          Choose a wallet that you want to connect.
        </Typography>
        {children}
      </ModalContents>
    </Drawer>
  );
};

export default WalletDrawer;
