import styled from '@emotion/styled';
import { breakpoints, palette } from '@playdapp/ui';

import OpenMenuHeader2 from './OpenMenuHeader2';

type Props = {
  children: React.ReactNode;
  title: string;
  handleOpen: () => void;
  isOpen: boolean;
};

const HeaderMenuBlock = styled.div<{
  isOpen: boolean;
}>`
  overflow-y: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${palette.white};
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(100%)')};
  z-index: 100;

  ${breakpoints.down('lg')} {
    min-height: 100svh;
  }
`;

const HeaderMenu = ({ children, title, handleOpen, isOpen }: Props) => (
  <HeaderMenuBlock isOpen={isOpen}>
    <OpenMenuHeader2 title={title} handleOpen={handleOpen} />
    {children}
  </HeaderMenuBlock>
);

export default HeaderMenu;
