import React, { useEffect, useMemo, useState } from 'react';
import Link from 'next/link';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  Button,
  breakpoints,
  fontSize,
  fontStyle,
  palette,
  spacing,
  Typography,
  Progress,
} from '@playdapp/ui';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Accordion,
  Tooltip,
} from '@chakra-ui/react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDisconnect } from 'wagmi';

import type { TokenCurrency } from 'types/wallet';

import useSolana from 'hooks/useSolana';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { resetWalletInfo } from 'store/wallet';
import { resetUserInfo } from 'store/user';
import { setPageNetwork } from 'store/page';
import {
  setDepositStatus,
  setInputDeposit,
  setIsDepositBoxOpen,
  setWithdrawStatus,
  setInputWithdraw,
  setIsWithdrawBoxOpen,
  depositToEPA,
  withdrawFromEPA,
} from 'store/marketplaceSolana';
import { convertAddress, toPascalCase, handlePriceValidation } from 'lib/util';
import { chainIdToMainNetworkName } from 'lib/network';
import { removeLoginInfo } from 'lib/auth';
import Color from 'styles/Color';

import Icon from './Icon';
import Image from './Image';
import Redirect from '@/components/Redirect';

type SolanaSendType = 'deposit' | 'withdraw';

const UserInfoBlock = styled.div`
  position: absolute;
  top: 3.25rem;
  right: 0;
  width: ${({ theme }) => theme.rem(345)};
  background-color: ${palette.white};
  border-radius: 16px;
  box-shadow: 0 1.75rem 2rem 0 rgba(76, 79, 110, 0.2);
  z-index: 10;
  overflow: hidden;
  ${breakpoints.down('md')} {
    position: absolute;
    top: 3.5rem;
    right: 50%;
    width: 100%;
    max-width: none;
    height: calc(100vh - 3.5rem);
    box-shadow: none;
    transform: translateX(50%);
    overflow-y: scroll;
    background-color: ${palette.white};
    border-radius: 0;
  }
  @media (max-width: 480px) {
    padding-bottom: 7rem;
    @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
      padding-bottom: 0;
    }
  }
`;

const UserInfoContent = styled.div`
  ${breakpoints.down('lg')} {
    width: 100%;
    margin: auto;
  }
`;

const AccountBlock = styled.div`
  display: flex;
  justify-content: center;
  margin: ${spacing.s} ${spacing.s} ${spacing.xxs} ${spacing.s};
  padding: 0.75rem;
  background-color: ${palette.gray200};
  border-radius: 8px;
`;

const TitleBlock = styled.div`
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const ContentsBlock = styled.div`
  padding: 0.75rem;
  border-radius: 4px;
  background-color: ${palette.white};
`;

const UserProfileBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  span {
    margin-left: ${spacing.s};
    color: ${palette.dgray600};
  }
`;

const profileLink = css`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  padding: 0;
  width: 2.55rem;
  height: 2.55rem;
  &:hover {
    opacity: 0.9;
  }
  &:active {
    opacity: 0.8;
  }
`;

const ProfileImageLink = styled.div`
  ${profileLink};
  min-width: 40px;
  min-height: 40px;

  span {
    border-radius: 50%;
  }
`;

const ProfileIconLink = styled.div`
  ${profileLink};

  img {
    border: 1px solid ${palette.gray600} !important;
    border-radius: 8px;
  }
`;

const EmailBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    margin-right: ${spacing.xxs};
  }
`;

const VerifyButton = styled(Button)`
  width: 122px;
  ${fontStyle('p4')}
  border-radius: 6px;
`;

const ProfileNickname = styled.div`
  ${fontStyle('p3')};
  color: ${palette.black};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const NetworkNameBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 ${spacing.xs};
  border-radius: 4px;
  background-color: ${palette.gray200};
  span {
    position: relative;
    z-index: 1;
    ${fontStyle('p5')};
  }
`;

const GreenDot = styled.div`
  display: inline-block;
  width: ${spacing.xxs};
  height: ${spacing.xxs};
  margin-right: ${spacing.xxs};
  border-radius: 50%;
  background-color: ${palette.green500};
`;

const BalanceBlock = styled.div`
  position: relative;
  width: 100%;
`;

const BalanceInfoBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 10;
  background-color: ${palette.white};
  text-align: center;
`;

const AccountInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-bottom: ${spacing.xs};
  margin: 0 auto ${spacing.s} auto;
  border-bottom: 1px solid ${palette.gray300};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const NetworkBlock = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${spacing.m};
  gap: ${spacing.xxs};
`;

const AccountTextButton = styled.button`
  position: relative;
  span {
    &:hover {
      color: ${palette.dgray600};
    }
    &:active {
      color: ${palette.dgray400};
    }
  }
`;

const WalletBlock = styled.div`
  margin-top: ${spacing.xs};
  button {
    margin-left: ${spacing.xs};
  }
`;

const CurrencyTokenBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 ${spacing.s};
`;

const CurrencyTokenInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  & + & {
    margin-top: ${spacing.l};
  }
`;

const CurrencyTokenTextBox = styled.div`
  display: flex;
  justify-content: flex-end;
  span {
    color: ${palette.black};
    text-align: left;
    ${fontStyle('p4')}
  }
  span:last-of-type {
    margin-left: ${spacing.xs};
    color: ${palette.dgray100};
  }
`;

const MenuBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.25rem 1.75rem ${spacing.m} 1.75rem;
  & > a + a {
    margin-top: ${spacing.xl};
  }
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  margin: ${spacing.xs} 0;
  cursor: pointer;
  &:hover {
    background-color: ${palette.gray200};
  }
`;

const MenuLink = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0.75rem 0.625rem;
  font-size: ${fontSize.p4};
  font-weight: 400;
  line-height: 1.5;
  color: ${palette.dgray600};
  span {
    margin-left: 0.625rem;
  }
`;

const SignOutButton = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0.75rem 0.625rem;
  ${fontStyle('p4')};
  color: ${palette.dgray600};
  & > span {
    margin-left: 0.625rem;
  }
`;

const SolanaWalletBlock = styled(AccordionItem)`
  border: none;
  margin-top: ${spacing.l};
`;

const SolanaWalletBox = styled.div`
  display: flex;
  align-items: center;
  height: ${({ theme }) => theme.rem(42)};
`;

const SolanaWalletContents = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 54px);
  margin-left: ${spacing.s};
`;

const SolanaWalletTextBox = styled.div`
  width: 100%;
  padding: 0;
  margin: 0;
  text-align: left;
`;

const CustomAccordionButton = styled(AccordionButton)<{ isLoad: boolean }>`
  width: ${({ theme }) => theme.rem(50)};
  ${({ isLoad }) =>
    isLoad
      ? `
    &:hover {
      background-color: #ffffff;
    }
  `
      : `
  cursor: default;
    &:hover {
      background-color: #ffffff;
    }
  `}
`;

const CustomAccordionPanel = styled(AccordionPanel)`
  padding: 0;
`;

const SendSolanaBox = styled.div`
  margin-top: ${spacing.s};
  padding: ${spacing.s};
  background-color: ${palette.gray200};
  border-radius: 8px;
  text-align: left;
  transition: all 0.2s;
`;

const SendSolanaSuccessBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: ${spacing.xs};
  padding: ${spacing.s};
  background: rgba(235, 249, 247, 0.9);
  border: 1px solid ${palette.primary600};
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(30, 31, 36, 0.15);
  span {
    ${fontStyle('p3')};
    color: ${palette.dgray600};
    padding-left: ${spacing.s};
    text-align: left;
  }
`;

const SendSolanaInputBlock = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${spacing.s};
`;

const SendSolanaInputErrorBlock = styled.div`
  padding: 0 ${spacing.xs};
  margin-top: ${spacing.xs};
  span {
    ${fontStyle('p4')};
    color: #e59c2e;
  }
`;

const SendSolanaInputBox = styled.div`
  width: calc(100% - 36px);
  padding: ${spacing.xs} ${spacing.m};
  background: #ffffff;
  border: 1px solid #d0d1d7;
  border-radius: 12px;
`;

const SendSolanaInput = styled.input`
  width: calc(100% - 32px);
  outline: none;
  color: ${palette.black};
  ${fontStyle('p2')};
  ::placeholder {
    color: ${palette.gray900};
  }
`;

const SendSolanaInputCurrency = styled.span`
  color: ${palette.dgray400};
  ${fontStyle('b3')}
`;

const BalanceTooltipBlock = styled.div`
  width: 263px;
  margin-top: ${spacing.xs};
  padding-left: 0.75rem;
  > span {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  span.title {
    ${fontStyle('p4')};
    color: ${palette.gray800};
    margin-left: ${spacing.xxs};
    border: 1px solid #ffffff;
    text-decoration: underline;
  }
`;

const CustomTooltip = styled(Tooltip)`
  width: 273px;
  padding: ${spacing.xxs} ${spacing.s};
  border-radius: 8px;
  .chakra-tooltip__arrow-wrapper {
    left: -42.5% !important;
  }
`;

const BalanceTooltipLabelTitle = styled.p`
  ${fontStyle('b4')};
`;

const BalanceTooltipLabelText = styled.p`
  width: 100%;
  ${fontStyle('p4')};
`;

const BalanceErrorBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${spacing.l};
  button {
    display: flex;
    align-items: center;
  }
  span {
    margin-left: ${spacing.xs};
    ${fontStyle('p4')};
  }
`;

const SendSolanaButton = styled.button<{ color: string; isSuccess: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;
  margin-left: ${spacing.s};
  border-radius: 50%;
  background-color: ${({ isSuccess, color }) => {
    if (isSuccess) return palette.blue500;
    return color;
  }};
  transition: all 0.2s;
  :hover {
    background-color: ${({ color, isSuccess }) =>
      Color(isSuccess ? palette.blue500 : color).darker(5)};
  }
  span img {
    width: 15.34px !important;
  }
`;

const AccountCopyButton = React.forwardRef<
  HTMLButtonElement,
  {
    account: string;
    accountStartIndex?: number;
    accountEndIndex?: number;
    handleCopy: () => void;
  }
>(({ account, accountStartIndex, accountEndIndex, handleCopy }, ref) => (
  <>
    <Typography type="p3" color="dgray100" component="span">
      {convertAddress(account, accountStartIndex, accountEndIndex)}
    </Typography>
    <CopyToClipboard text={account} onCopy={handleCopy}>
      <AccountTextButton ref={ref}>
        <Icon name="copy-dgray100" width={13.33} height={13.33} />
      </AccountTextButton>
    </CopyToClipboard>
  </>
));

const SolanaBalance = ({
  isLoading,
  isError,
  balance,
}: {
  isLoading: boolean;
  isError: boolean;
  balance: number | null;
}) => {
  const text = isLoading ? 'loading...' : 'Load Failed';

  return (
    <Typography
      type={isLoading || isError ? 'p4' : 'b4'}
      color={isError ? 'gray800' : 'black'}
      component="p"
    >
      {isLoading || isError || balance === null ? (
        text
      ) : (
        <>{balance.toFixed(4)} SOL</>
      )}
    </Typography>
  );
};

const SolanaBalanceIcon = ({
  isLoading,
  isError,
  isOpen,
}: {
  isLoading: boolean;
  isError: boolean;
  isOpen: boolean;
}) => {
  if (isLoading) {
    return <Progress color={palette.primary600} size="small" />;
  }
  if (isError) {
    return <Icon name="error-yellow" width={17.5} height={15.31} />;
  }
  return (
    <Icon
      name={`arrow-${isOpen ? 'up' : 'down'}`}
      width={11.77}
      height={6.77}
    />
  );
};

const BalanceTooltipLabel = () => (
  <>
    <BalanceTooltipLabelTitle>Escrow wallet?</BalanceTooltipLabelTitle>
    <BalanceTooltipLabelText>
      This escrow-like side wallet is used to trade items by offering sellers
      prices.
    </BalanceTooltipLabelText>
    <BalanceTooltipLabelText>
      The wallet must contain the offering amount to execute trading when
      purchasing an item through the offer.
    </BalanceTooltipLabelText>
  </>
);

const UserInfo2 = () => {
  const [copied, setCopied] = useState(false);
  const [isQuestionClicked, setIsQuestionClicked] = useState(false);

  const {
    account,
    balance,
    isBalanceLoading,
    isBalanceError,
    provider,
    chainId,
    solanaWalletAdapter,
  } = useAppSelector(({ wallet }) => wallet);

  const {
    solanaConnector,
    depositStatus,
    inputDeposit,
    isDepositBoxOpen,
    withdrawStatus,
    inputWithdraw,
    isWithdrawBoxOpen,
  } = useAppSelector(({ marketplaceSolana }) => marketplaceSolana);

  const { alias, email, isVerified, profileTokenId, membersMikeyGrade } =
    useAppSelector(({ user }) => user);

  const dispatch = useAppDispatch();
  const { deactivate } = useWeb3React<Web3Provider>();
  const { disconnect, updateBalance } = useSolana();
  const { disconnect: wagmiDisconnect } = useDisconnect();

  const networkName = useMemo(
    () => (chainId ? chainIdToMainNetworkName[chainId] : null),
    [chainId],
  );

  const AccordionIndexList = useMemo(() => {
    let result: number[] = [];
    if (isDepositBoxOpen) result = result.concat(0);
    if (isWithdrawBoxOpen) result = result.concat(1);
    return result;
  }, [isDepositBoxOpen, isWithdrawBoxOpen]);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const handleLogout = () => {
    removeLoginInfo();
    dispatch(resetWalletInfo());
    dispatch(resetUserInfo());
    dispatch(setPageNetwork(null));
    deactivate();
    disconnect();
    wagmiDisconnect();
  };

  const handleSolInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    balance: number,
    type: SolanaSendType,
  ) => {
    const currentInputValue = handlePriceValidation(
      e.target.value,
      balance,
      9999,
    );

    if (type === 'deposit') {
      dispatch(setInputDeposit(currentInputValue));
      dispatch(
        setDepositStatus(
          !currentInputValue || (currentInputValue && +currentInputValue === 0)
            ? 'nothing'
            : 'input',
        ),
      );
    }

    if (type === 'withdraw') {
      dispatch(setInputWithdraw(currentInputValue));
      dispatch(
        setWithdrawStatus(
          !currentInputValue || (currentInputValue && +currentInputValue === 0)
            ? 'nothing'
            : 'input',
        ),
      );
    }
  };

  const handleSolanaSendClick = async (type: SolanaSendType) => {
    if (
      !solanaConnector ||
      !solanaWalletAdapter ||
      (type === 'deposit' && (!inputDeposit || +inputDeposit === 0)) ||
      (type === 'withdraw' && (!inputWithdraw || +inputWithdraw === 0)) ||
      !balance
    )
      return;

    if (type === 'deposit') dispatch(depositToEPA({ amount: +inputDeposit }));
    if (type === 'withdraw')
      dispatch(withdrawFromEPA({ amount: +inputWithdraw }));
  };

  const handleQuestionClick = () => {
    setIsQuestionClicked(!isQuestionClicked);
  };

  useEffect(() => {
    if (depositStatus === 'success' || depositStatus === 'error') {
      updateBalance();

      setTimeout(() => {
        dispatch(setDepositStatus('nothing'));
        if (depositStatus === 'success') dispatch(setInputDeposit(''));
      }, 5000);
    }

    if (withdrawStatus === 'success' || withdrawStatus === 'error') {
      updateBalance();

      setTimeout(() => {
        dispatch(setWithdrawStatus('nothing'));
        if (withdrawStatus === 'success') dispatch(setInputWithdraw(''));
      }, 5000);
    }
  }, [depositStatus, withdrawStatus]);

  useEffect(() => {
    updateBalance();
  }, []);

  return (
    <UserInfoBlock>
      <UserInfoContent>
        <AccountBlock>
          <TitleBlock>
            <UserProfileBlock>
              <Link href="/my-page/items?my-items" passHref>
                {profileTokenId &&
                membersMikeyGrade &&
                networkName === 'polygon' ? (
                  <ProfileImageLink>
                    <Image
                      src={`${process.env.NEXT_PUBLIC_MEMBERS_IMAGE_URL}/${membersMikeyGrade}/gif/${profileTokenId}.gif`}
                      layout="fixed"
                      objectFit="cover"
                      width={40}
                      height={40}
                    />
                  </ProfileImageLink>
                ) : (
                  <ProfileIconLink>
                    <Image
                      prefix="s3"
                      src="/mypage/empty-stake.png"
                      layout="fixed"
                      objectFit="cover"
                      width={40}
                      height={40}
                    />
                  </ProfileIconLink>
                )}
              </Link>
              <EmailBlock>
                <Typography type="b3" color="black">
                  {email}
                </Typography>
                {isVerified ? (
                  <Icon name="verify" width={16} height={16} />
                ) : (
                  <Redirect to="/email/verification">
                    <VerifyButton>Verify Email</VerifyButton>
                  </Redirect>
                )}
              </EmailBlock>
            </UserProfileBlock>

            <ContentsBlock>
              {networkName && provider && (
                <BalanceBlock>
                  <BalanceInfoBlock>
                    {account && (
                      <AccountInfo>
                        <NetworkBlock>
                          <Icon name={`${provider}-square`} size={24} />

                          <NetworkNameBlock>
                            <GreenDot />
                            <span>{toPascalCase(networkName)}</span>
                          </NetworkNameBlock>
                        </NetworkBlock>

                        <Link href="/my-page/items" passHref>
                          <a>
                            <ProfileNickname>
                              {alias.length > 30
                                ? convertAddress(alias, 0, alias.length)
                                : alias}
                            </ProfileNickname>
                          </a>
                        </Link>
                        <WalletBlock>
                          <Tooltip
                            label="Copied!"
                            isOpen={copied}
                            placement="top"
                          >
                            {account && (
                              <AccountCopyButton
                                account={account}
                                handleCopy={handleCopy}
                              />
                            )}
                          </Tooltip>
                        </WalletBlock>
                      </AccountInfo>
                    )}
                    {!isBalanceLoading &&
                      balance &&
                      networkName !== 'solana' && (
                        <CurrencyTokenBlock>
                          {Object.keys(balance).map((currency) => (
                            <CurrencyTokenInfo key={currency}>
                              <Icon
                                name={`currency-${currency as TokenCurrency}`}
                                size={24}
                              />
                              <CurrencyTokenTextBox>
                                <span>{balance[currency].toFixed(4)}</span>
                                <span>{currency.toUpperCase()}</span>
                              </CurrencyTokenTextBox>
                            </CurrencyTokenInfo>
                          ))}
                        </CurrencyTokenBlock>
                      )}
                    {networkName === 'solana' && (
                      <>
                        <Accordion index={AccordionIndexList}>
                          <SolanaWalletBlock>
                            <SolanaWalletBox>
                              <Icon name="solana" size={42} />
                              <SolanaWalletContents>
                                <SolanaWalletTextBox>
                                  <Typography
                                    type="p4"
                                    color="black"
                                    component="p"
                                  >
                                    Main Wallet
                                  </Typography>
                                  <SolanaBalance
                                    isLoading={isBalanceLoading}
                                    isError={isBalanceError}
                                    balance={balance ? balance.sol : null}
                                  />
                                </SolanaWalletTextBox>
                                <CustomAccordionButton
                                  isLoad={!isBalanceLoading && !isBalanceError}
                                  _focus={{ boxShadow: 'none' }}
                                  onClick={() => {
                                    if (!isBalanceLoading && !isBalanceError) {
                                      dispatch(
                                        setIsDepositBoxOpen(!isDepositBoxOpen),
                                      );
                                      setIsQuestionClicked(false);
                                    }
                                  }}
                                >
                                  <SolanaBalanceIcon
                                    isLoading={isBalanceLoading}
                                    isError={isBalanceError}
                                    isOpen={isDepositBoxOpen}
                                  />
                                </CustomAccordionButton>
                              </SolanaWalletContents>
                            </SolanaWalletBox>
                            <CustomAccordionPanel>
                              <SendSolanaBox>
                                <Typography
                                  type="p4"
                                  color="black"
                                  component="p"
                                >
                                  Deposit to Escrow wallet
                                </Typography>
                                <SendSolanaInputBlock>
                                  <SendSolanaInputBox>
                                    <SendSolanaInput
                                      type="string"
                                      value={inputDeposit || ''}
                                      placeholder="0"
                                      disabled={depositStatus === 'loading'}
                                      onChange={(e) =>
                                        balance &&
                                        handleSolInputChange(
                                          e,
                                          +balance.sol.toFixed(4),
                                          'deposit',
                                        )
                                      }
                                    />
                                    <SendSolanaInputCurrency>
                                      SOL
                                    </SendSolanaInputCurrency>
                                  </SendSolanaInputBox>
                                  <SendSolanaButton
                                    color={
                                      depositStatus === 'input' ||
                                      depositStatus === 'loading'
                                        ? palette.primary600
                                        : palette.gray300
                                    }
                                    isSuccess={depositStatus === 'success'}
                                    onClick={() =>
                                      handleSolanaSendClick('deposit')
                                    }
                                  >
                                    {depositStatus === 'loading' && (
                                      <Progress
                                        color={palette.white}
                                        size="small"
                                      />
                                    )}
                                    {(depositStatus === 'nothing' ||
                                      depositStatus === 'input' ||
                                      depositStatus === 'error') && (
                                      <Icon
                                        name={`arrow-right-v4${
                                          depositStatus === 'input'
                                            ? '-white'
                                            : ''
                                        }`}
                                        width={15.34}
                                        height={12.85}
                                      />
                                    )}
                                    {depositStatus === 'success' && (
                                      <Icon
                                        name="check-awesome"
                                        width={12.01}
                                        height={9.27}
                                      />
                                    )}
                                  </SendSolanaButton>
                                </SendSolanaInputBlock>
                                {depositStatus === 'error' && (
                                  <SendSolanaInputErrorBlock>
                                    <span>Unexpected error occurred</span>
                                  </SendSolanaInputErrorBlock>
                                )}
                              </SendSolanaBox>
                              {depositStatus === 'success' && (
                                <SendSolanaSuccessBox>
                                  <Icon name="check-green" size={24} />
                                  <span>Transaction completed!</span>
                                </SendSolanaSuccessBox>
                              )}
                            </CustomAccordionPanel>
                          </SolanaWalletBlock>
                          <SolanaWalletBlock>
                            <SolanaWalletBox>
                              <Icon name="solana" size={42} />
                              <SolanaWalletContents>
                                <SolanaWalletTextBox>
                                  <Typography
                                    type="p4"
                                    color="black"
                                    component="p"
                                  >
                                    Escrow Wallet
                                  </Typography>
                                  <SolanaBalance
                                    isLoading={isBalanceLoading}
                                    isError={isBalanceError}
                                    balance={balance ? balance.escrowSol : null}
                                  />
                                </SolanaWalletTextBox>
                                <CustomAccordionButton
                                  isLoad={!isBalanceLoading && !isBalanceError}
                                  _focus={{ boxShadow: 'none' }}
                                  onClick={() => {
                                    if (!isBalanceLoading && !isBalanceError) {
                                      dispatch(
                                        setIsWithdrawBoxOpen(
                                          !isWithdrawBoxOpen,
                                        ),
                                      );
                                      setIsQuestionClicked(false);
                                    }
                                  }}
                                >
                                  <SolanaBalanceIcon
                                    isLoading={isBalanceLoading}
                                    isError={isBalanceError}
                                    isOpen={isWithdrawBoxOpen}
                                  />
                                </CustomAccordionButton>
                              </SolanaWalletContents>
                            </SolanaWalletBox>
                            <CustomAccordionPanel>
                              <SendSolanaBox>
                                <Typography
                                  type="p4"
                                  color="black"
                                  component="p"
                                >
                                  Withdraw to Main wallet
                                </Typography>
                                <SendSolanaInputBlock>
                                  <SendSolanaInputBox>
                                    <SendSolanaInput
                                      type="string"
                                      value={inputWithdraw || ''}
                                      placeholder="0"
                                      disabled={withdrawStatus === 'loading'}
                                      onChange={(e) =>
                                        balance &&
                                        handleSolInputChange(
                                          e,
                                          +balance.escrowSol.toFixed(4),
                                          'withdraw',
                                        )
                                      }
                                    />
                                    <SendSolanaInputCurrency>
                                      SOL
                                    </SendSolanaInputCurrency>
                                  </SendSolanaInputBox>
                                  <SendSolanaButton
                                    color={
                                      withdrawStatus === 'input' ||
                                      withdrawStatus === 'loading'
                                        ? palette.primary600
                                        : palette.gray300
                                    }
                                    isSuccess={withdrawStatus === 'success'}
                                    onClick={() =>
                                      handleSolanaSendClick('withdraw')
                                    }
                                  >
                                    {withdrawStatus === 'loading' && (
                                      <Progress
                                        color={palette.white}
                                        size="small"
                                      />
                                    )}
                                    {(withdrawStatus === 'nothing' ||
                                      withdrawStatus === 'input' ||
                                      withdrawStatus === 'error') && (
                                      <Icon
                                        name={`arrow-right-v4${
                                          withdrawStatus === 'input'
                                            ? '-white'
                                            : ''
                                        }`}
                                        width={15.34}
                                        height={12.85}
                                      />
                                    )}
                                    {withdrawStatus === 'success' && (
                                      <Icon
                                        name="check-awesome"
                                        width={12.01}
                                        height={9.27}
                                      />
                                    )}
                                  </SendSolanaButton>
                                </SendSolanaInputBlock>
                                {withdrawStatus === 'error' && (
                                  <SendSolanaInputErrorBlock>
                                    <span>Unexpected error occurred</span>
                                  </SendSolanaInputErrorBlock>
                                )}
                              </SendSolanaBox>
                              {withdrawStatus === 'success' && (
                                <SendSolanaSuccessBox>
                                  <Icon name="check-green" size={24} />
                                  <span>Transaction completed!</span>
                                </SendSolanaSuccessBox>
                              )}
                            </CustomAccordionPanel>
                          </SolanaWalletBlock>
                        </Accordion>
                        {!isBalanceLoading && !isBalanceError && (
                          <BalanceTooltipBlock>
                            <CustomTooltip
                              shouldWrapChildren
                              hasArrow
                              label={<BalanceTooltipLabel />}
                              bgColor="#2F3037"
                              textColor="#ffffff"
                              placement="bottom"
                              isOpen={isQuestionClicked}
                            >
                              <Icon
                                name="question-mark-light-gray"
                                size={20}
                                onClick={handleQuestionClick}
                              />
                              <span
                                role="button"
                                tabIndex={0}
                                className="title"
                                onClick={handleQuestionClick}
                                onKeyDown={handleQuestionClick}
                              >
                                Escrow wallet?
                              </span>
                            </CustomTooltip>
                          </BalanceTooltipBlock>
                        )}
                        {isBalanceError && (
                          <BalanceErrorBlock>
                            <Button
                              variant="outline"
                              color="primary"
                              onClick={() => updateBalance()}
                            >
                              <Icon
                                name="refresh-primary"
                                width={11.42}
                                height={13.34}
                              />
                              <span>Reload Account</span>
                            </Button>
                          </BalanceErrorBlock>
                        )}
                      </>
                    )}
                  </BalanceInfoBlock>
                </BalanceBlock>
              )}
            </ContentsBlock>
          </TitleBlock>
        </AccountBlock>

        <MenuBlock>
          <Menu>
            <Link href="/my-page/items?my-items" passHref>
              <MenuLink>
                <Icon name="menu-account" width={12} height={12} />
                <span>My account</span>
              </MenuLink>
            </Link>
          </Menu>
          <hr />
          <Menu>
            <SignOutButton onClick={handleLogout}>
              <Icon name="power" width={12} height={12.67} />
              <span>Sign Out</span>
            </SignOutButton>
          </Menu>
        </MenuBlock>
      </UserInfoContent>
    </UserInfoBlock>
  );
};

export default UserInfo2;
