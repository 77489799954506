import api from 'api';
import type ResponseResult from 'types/response';

import {
  AlarmRequest,
  AlarmResponseData,
  AlarmDeleteRequest,
  AlarmRedDotRequest,
  PatchAlarmSettingRequest,
  AlarmSettingRequest,
  Setting,
} from 'types/alarm';

export const getAlarm = async ({
  account,
  page,
  startList,
  endList,
}: AlarmRequest): Promise<ResponseResult<AlarmResponseData>> =>
  api.get(
    `/alarm?address=${account}&page=${page}&market=playdapp&startList=${startList}&endList=${endList}`,
  );

export const deleteAlarm = async ({
  account,
  index,
  type,
}: AlarmDeleteRequest) =>
  api.delete(
    `/alarm?address=${account}${index ? `&index=${index}` : ''}&type=${type}`,
  );

export const getAlarmRedDot = async ({ account }: AlarmRedDotRequest) =>
  api.get(`/alarm/new?address=${account}`);

export const patchAlarmSetting = async ({
  address,
  offer,
  outbid,
  event,
  nftDrops,
  collection,
}: PatchAlarmSettingRequest) =>
  api.patch(`/alarm/setting`, {
    address,
    offer,
    outbid,
    event,
    nftDrops,
    collection,
  });

export const getAlarmSetting = async ({
  account,
}: AlarmSettingRequest): Promise<ResponseResult<Setting>> =>
  api.get(`/alarm/setting?address=${account}`);
