import { gql } from 'graphql-request';

export const ValueInfo = gql`
  fragment ValueInfo on ValueInfo {
    createdAt
    currency
    expireAt
    price
    onBidding
  }
`;

export const Status = gql`
  fragment Status on Status {
    isSale
    isOffer
    isAuction
    isBundle
  }
`;

export const Collection = gql`
  fragment Collection on Collection {
    categoryId
    homepage
    imageUrl
    network
    thumbnailUrl
    coverImageUrl
    cardBannerImageUrl
    title
  }
`;

export const Order = gql`
  fragment Order on Order {
    auction {
      minBidPrice
      minBidCurrency
      highestBidPrice
      highestBidCurrency
      createdAt
      expireAt
    }
    offer {
      offerTotalCount
      highestOfferPrice
      highestOfferCurrency
      highestOfferCreatedAt
    }
    sale {
      price
      currency
      createdAt
    }
    lastSoldInfo {
      price
      currency
      soldAt
    }
  }
`;

export const Asset = gql`
  ${Collection}
  ${Order}
  fragment Asset on Asset {
    animationUri
    owner
    tokenId
    network
    assetId
    name
    imageUri
    assetTitle
    isInBundle
    assetAddress
    likeCount
    order {
      ...Order
    }
    attributes
    collectionInfo {
      ...Collection
    }
  }
`;
