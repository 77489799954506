import React, { useEffect, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import Link from 'next/link';
import styled from '@emotion/styled';
import {
  breakpoints,
  fontStyle,
  spacing,
  palette,
  Typography,
} from '@playdapp/ui';
import { formatDistanceToNow, fromUnixTime } from 'date-fns';

import { deleteAlarm } from 'api/alarm';
import { useAppSelector } from 'store/hooks';
import useToastMessage from 'hooks/useToastMessage';

import Icon from './Icon';
import Dot from './Dot';

type Props = {
  type?: string;
  timestamp?: number;
  link?: string;
  content?: string;
  index?: number;
  tokenNetwork?: string;
  showDot?: boolean;
  openModal?: (type: string, index?: number) => void;
  onDataRefetch?: () => void;
};

type StyleProps = {
  clickable: boolean;
};

const Notification = styled.div<StyleProps>`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: ${spacing.s};
  border: ${(props) =>
    props.clickable
      ? `1px solid ${palette.primary300}`
      : `1px solid ${palette.gray400}`};
  background-color: ${(props) =>
    props.clickable ? `${palette.primary100}` : `${palette.white}`};
  border-radius: 8px;
  margin: ${spacing.xxs} 0;

  cursor: ${(props) => props.clickable && `pointer`};

  ${breakpoints.down('md')} {
    display: flex;
    padding: ${spacing.s};
  }

  :last-child {
    .Menu {
      position: absolute;
      bottom: 5px;
      right: 30px;
    }
  }
`;

const NotificationContent = styled.div`
  width: 100%;
  margin-right: 4px;
`;

const NotificationTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  span {
    margin-left: ${spacing.xxs};
  }
`;

const NotificationDescription = styled.div`
  display: flex;
`;

const NotificationMenu = styled.button`
  display: flex;
  align-items: center;
`;

const MenuModalBlock = styled.div`
  position: absolute;
  bottom: -35px;
  right: 30px;
  width: 178px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 500;
  background: ${palette.white};
  box-shadow: 0px 4px 8px rgba(30, 31, 36, 0.15);
  border-radius: 8px;
  border: none;
  padding: ${spacing.m} ${spacing.s};
`;

const DeleteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 154px;
  height: 32px;
  background-color: ${palette.red500};
  margin-bottom: ${spacing.m};
  padding: 1.125rem ${spacing.xs};
  border-radius: 4px;
  color: ${palette.white};
  ${fontStyle('p5')}

  :hover {
    background-color: ${palette.red600};
  }
`;

const BlockButton = styled.button`
  text-align: center;
  color: ${palette.gray800};
  ${fontStyle('p6')};

  :hover {
    color: ${palette.gray900};
  }
`;

const NotificationTime = styled.div``;

const Notifications = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      type,
      timestamp,
      link,
      content,
      index,
      tokenNetwork,
      showDot,
      openModal,
      onDataRefetch,
    },
    ref,
  ) => {
    const { account } = useAppSelector(({ wallet }) => wallet);
    const [isOpenMenu, setIsOpenMenu] = useState(false);

    const { handleUpdateToast, handleCloseToast } = useToastMessage({
      position: 'top',
      duration: 5000,
    });

    const clickRef = useRef(null);

    useClickAway(clickRef, () => {
      setIsOpenMenu(false);
    });

    const handleMenu = () => {
      setIsOpenMenu(!isOpenMenu);
    };

    const handleBlockAlarm = async () => {
      try {
        if (account) {
          await deleteAlarm({
            account,
            index,
            type: 'block',
          });
        }
        onDataRefetch && onDataRefetch();
        setIsOpenMenu(false);
      } catch (err) {
        handleUpdateToast('Please try again later.', 'error', true);
      }
    };

    useEffect(() => {
      handleCloseToast();
    }, [handleCloseToast]);

    return (
      <Notification ref={ref} key={index} clickable={!!link}>
        <NotificationContent>
          <NotificationTitle>
            <Link href={link || '/'} passHref>
              <NotificationDescription>
                {type === 'get_offer' && <Typography type="b5">📫</Typography>}
                {type === 'sell_nft' && <Typography type="b5">💰</Typography>}
                {type === 'transfer_nft' && (
                  <Typography type="b5">🎁</Typography>
                )}
                {type === ('start_event' || 'reward_event' || 'end_event') && (
                  <Typography type="b5">🎉</Typography>
                )}
                {type === 'drops' && <Typography type="b5">📣</Typography>}
                {type === ('update_price' || 'higher_offer') && (
                  <Icon
                    name={tokenNetwork === 'polygon' ? 'polygon' : 'ethereum'}
                    size={16}
                  />
                )}
                <Typography type="b5">{content}</Typography>
                {showDot && <Dot left="0.5rem" />}
              </NotificationDescription>
            </Link>
            <NotificationMenu
              onClick={() => {
                handleMenu();
              }}
            >
              <Icon name="dots-vertical" size={16} />
            </NotificationMenu>
          </NotificationTitle>
          <Link href={link || '/'} passHref>
            <NotificationTime>
              <Typography type="p6" color="dgray300">
                {timestamp && formatDistanceToNow(fromUnixTime(timestamp))}
              </Typography>
            </NotificationTime>
          </Link>
        </NotificationContent>
        {isOpenMenu && openModal && (
          <MenuModalBlock className="Menu" ref={clickRef}>
            <DeleteButton
              onClick={() => {
                openModal('single', index);
              }}
            >
              Delete this message
            </DeleteButton>
            <BlockButton onClick={handleBlockAlarm}>
              Block this message type
            </BlockButton>
          </MenuModalBlock>
        )}
      </Notification>
    );
  },
);

export default Notifications;
