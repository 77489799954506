import styled from '@emotion/styled';
import { breakpoints, Modal, spacing, Button, fontStyle } from '@playdapp/ui';
import { deleteAlarm } from 'api/alarm';
import useToastMessage from 'hooks/useToastMessage';
import { useEffect } from 'react';
import { useAppSelector } from 'store/hooks';
import { MessageType } from 'types/event';

type Props = {
  type: string;
  index?: number;
  isOpen: boolean;
  handleOpen: (isOpen: boolean) => void;
};

const ModalTitle = styled.div`
  ${fontStyle('h4')};
  text-align: center;
  margin-bottom: ${spacing.s};

  ${breakpoints.down('md')} {
    ${fontStyle('h5')};
  }
`;

const ModalDescription = styled.div`
  ${fontStyle('p3')};
  text-align: center;
  margin-bottom: ${spacing.xxl};

  ${breakpoints.down('md')} {
    ${fontStyle('p4')};
    margin-bottom: ${spacing.l};
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${spacing.s};

  button {
    flex: 1;
    max-width: ${({ theme }) => theme.rem(205)};
    font-weight: 600;
  }

  ${breakpoints.down('md')} {
    flex-direction: column-reverse;

    button {
      width: 100%;
    }
  }
`;

function DeleteModal({ isOpen, type, index, handleOpen }: Props) {
  const { account } = useAppSelector(({ wallet }) => wallet);

  const { handleUpdateToast, handleCloseToast } = useToastMessage({
    position: 'top',
    duration: 5000,
  });

  const handleAllDelete = async () => {
    if (account) {
      const res = await deleteAlarm({ account, type: 'all' });

      let message = 'The notification card is successfully deleted!';
      let status = 'newSuccess' as MessageType;
      if (!res.data) {
        message = 'An error occured. Please try again later.';
        status = 'error';
      }
      handleUpdateToast(message, status, true);
      setTimeout(() => {
        handleOpen(false);
      }, 1000);
    }
  };

  const handleDelete = async () => {
    if (account) {
      const res = await deleteAlarm({
        account,
        index,
        type: 'delete',
      });

      let message = 'The notification card is successfully deleted!';
      let status = 'success' as MessageType;

      if (!res.data) {
        message = 'An error occured. Please try again later.';
        status = 'error';
      }

      handleUpdateToast(message, status, true);
      setTimeout(() => {
        handleOpen(false);
      }, 1000);
    }
  };

  useEffect(() => {
    handleCloseToast();
  }, [handleCloseToast]);

  return (
    <Modal isOpen={isOpen} handleOpen={handleOpen} hideCloseButton>
      <ModalTitle>Delete this notification?</ModalTitle>
      <ModalDescription>It cannot be returned.</ModalDescription>
      <ButtonGroup>
        <Button variant="outline" onClick={() => handleOpen(false)} size="lg">
          Cancel
        </Button>

        <Button
          onClick={type === 'all' ? handleAllDelete : handleDelete}
          size="lg"
        >
          OK
        </Button>
      </ButtonGroup>
    </Modal>
  );
}

export default DeleteModal;
