import React from 'react';
import styled from '@emotion/styled';
import { breakpoints, palette } from '@playdapp/ui';

import OpenMenuHeader from './OpenMenuHeader';

type Props = {
  children: React.ReactNode;
  handleOpen: () => void;
};

const HeaderMenuBlock = styled.div`
  overflow-y: none;
  position: fixed;
  inset: 0;
  width: 100%;
  background-color: ${palette.white};
  z-index: 1000;

  ${breakpoints.down('lg')} {
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }
`;

const HeaderMenu = ({ children, handleOpen }: Props) => (
  <HeaderMenuBlock>
    <OpenMenuHeader handleOpen={handleOpen} />
    {children}
  </HeaderMenuBlock>
);

export default HeaderMenu;
