import { gql } from 'graphql-request';
import graphQLClient from '.';
import { Asset } from './fragments';

type ResultSearchParams = {
  query: string;
};

const RESULT_SEARCH = gql`
  query Search($query: String!) {
    search(query: $query) {
      tokens {
        ...Asset
      }
      collections {
        ...Collection
      }
    }
  }
  ${Asset}
`;

const resultSearch = async (params: ResultSearchParams) => {
  const data = await graphQLClient.request(RESULT_SEARCH, params);
  const { search } = data;

  return search;
};

export default resultSearch;
