import styled from '@emotion/styled';
import { palette } from '@playdapp/ui';

type Props = {
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
};

const Container = styled.div<{
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
}>`
  position: absolute;
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  background-color: ${palette.red500};
`;

const TabNotification = (props: Props) => <Container {...props} />;

export default TabNotification;
